import {Divider, FormControlLabel, Paper as MuiPaper, Stack, styled, Switch, Typography} from "@mui/material";
import SliderWithEdit from "../SliderWithEdit";
import React, {useEffect, useState} from "react";
import {useJobFamilies, useJobTitles} from "../../hooks/ReferenceSearches";
import Select from "react-select";
import RegionTree from "./RegionTree";
import SectorTree from "./SectorTree";

const Paper = styled(MuiPaper)(({theme}) => ({
    background: theme.palette.payLevels.light,
    borderColor: theme.palette.contrastGrey.main,
}));

const FilterLabel = styled(Typography)(({theme}) => ({
    font: theme.typography.subtitle2.font,
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: 2,
}));

const jobLevels = [
    {value: 1, label: 'Level 1'},
    {value: 2, label: 'Level 2'},
    {value: 3, label: 'Level 3'},
    {value: 4, label: 'Level 4'},
    {value: 5, label: 'Level 5'},
    {value: 6, label: 'Level 6'},
    {value: 7, label: 'Level 7'},
    {value: 8, label: 'Level 8'},
    {value: 9, label: 'Level 9'},
    {value: 10, label: 'Level 10'}
];

const formatForReactSelect = (values) => {
    return values?.map((value) => ({
        value: value.id,
        label: value.name,
    })) || [];
};

const LevelFilters = ({filters, updateFilters}) => {
    const [familyOptions, setFamilyOptions] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);

    const {jobFamilies} = useJobFamilies();
    const {jobTitles} = useJobTitles(filters.jobFamily?.value);

    useEffect(() => {
        const defaultFamily = {value: 0, label: "All"};

        if (!Boolean(filters.jobFamily)) {
            filters.jobFamily = defaultFamily.value;
        }

        setFamilyOptions([defaultFamily, ...formatForReactSelect(jobFamilies)]);
        setRoleOptions(formatForReactSelect(jobTitles));

    }, [filters, jobFamilies, jobTitles]);

    const handleJobFamilyChange = (selectedFamily) => {
        filters.jobFamily = selectedFamily.value;
        filters.jobRoles = null;
        updateFilters();
    };

    const handleJobRoleChange = (selectedRoles) => {
        filters.jobRoles = selectedRoles.map(role => role.value);
        updateFilters();
    };

    const handleJobLevelChange = (selectedJobLevels) => {
        filters.jobLevels = selectedJobLevels.map(level => level.value);
        updateFilters();
    };

    const handleSalaryChange = (low, high) => {
        filters.salary = {min: low, max: high};
        updateFilters();
    };

    const handleAnonymousChange = (event) => {
        filters.anonymous = event.target.checked;
        updateFilters();
    };

    const rolePlaceHolder = (jobFamily) => {
        return jobFamily?.value ? `All ${jobFamily.label}` : "All";
    };

    const selectedFamily = familyOptions?.find(family => family.value === filters.jobFamily) || null;
    const selectedRoles = roleOptions?.filter(role => filters.jobRoles?.includes(role.value)) || [];
    const selectedLevels = jobLevels.filter(level => filters.jobLevels?.includes(level.value)) || [];

    return (
        <Paper variant="outlined">
            <Stack spacing={2} sx={{height: "100%", padding: "1rem", width: "15.5rem"}}>
                <Typography variant="h6">Filters</Typography>

                <Stack>
                    <FilterLabel>Job family</FilterLabel>
                    <Select
                        id="job-family-select"
                        options={familyOptions}
                        value={selectedFamily}
                        onChange={handleJobFamilyChange}
                    />
                </Stack>

                <Stack>
                    <FilterLabel>Job role</FilterLabel>
                    <Select
                        isMulti
                        options={roleOptions}
                        value={selectedRoles}
                        onChange={handleJobRoleChange}
                        closeMenuOnSelect={false}
                        placeholder={rolePlaceHolder(selectedFamily)}
                    />
                </Stack>
                <Divider/>

                <Stack>
                    <FilterLabel>Job Level</FilterLabel>
                    <Select
                        isMulti
                        options={jobLevels}
                        value={selectedLevels}
                        onChange={handleJobLevelChange}
                        closeMenuOnSelect={false}
                        placeholder={"All"}
                    />
                </Stack>
                <Divider/>

                <Stack>
                    <FilterLabel>Regions</FilterLabel>
                    <RegionTree filters={filters} updateFilters={updateFilters}/>
                </Stack>
                <Divider/>

                <Stack>
                    <FilterLabel>Sectors & Industries</FilterLabel>
                    <SectorTree filters={filters} updateFilters={updateFilters}/>
                </Stack>
                <Divider/>

                <Stack>
                    <FilterLabel>Salary range (&pound;)</FilterLabel>
                    <SliderWithEdit
                        low={filters.salary.min}
                        high={filters.salary.max}
                        min={0}
                        max={150000}
                        step={500}
                        fixed={0}
                        updateValues={handleSalaryChange}
                    />
                </Stack>
                <Divider/>

                <Stack>
                    <FilterLabel>Anonymous data</FilterLabel>
                    <FormControlLabel label="Exclude anonymous data" control={
                        <Switch
                            id="anonymous-switch"
                            checked={filters.anonymous}
                            onChange={handleAnonymousChange}
                            size="small"
                        />
                    }/>
                </Stack>
                <Divider/>

            </Stack>
        </Paper>
    );
};

export default LevelFilters;
