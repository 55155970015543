import {Box} from "@mui/material";
import PieChart from "highcharts-react-official";
import Highcharts from 'highcharts';
import {useEffect, useState} from "react";
import {generateChartTitle, pieChartOptions} from "./AwardChartUtils";

const YesNoPieChart = ({data, label, field}) => {
    const [chartData, setChartData] = useState([]);
    const [chartTitle, setChartTitle] = useState('');

    useEffect(() => {
        let yesCount = 0;
        data.rows.forEach((row) => {
            if (row[field]) {
                yesCount++;
            }
        });

        const yesPercent = +((yesCount * 100) / data.rows.length).toFixed(2);
        const noPercent = +((data.rows.length - yesCount) * 100 / data.rows.length).toFixed(2);

        setChartData([
            {
                name: "Yes",
                y: yesPercent,
            },
            {
                name: "No",
                y: noPercent,
            }
        ]);

        setChartTitle(generateChartTitle(data, label));

    }, [data, label, field]);

    const options = pieChartOptions(chartTitle, chartData);

    return (
        <Box>
            <PieChart highcharts={Highcharts} options={options}/>
        </Box>
    );
};

export default YesNoPieChart;
