import {Stack} from "@mui/material";
import HeaderBreadcrumb from "../components/HeaderBreadcrumb";
import AwardFilters from "../components/awards/AwardFilters";
import {useEffect, useRef, useState} from "react";
import AwardsHeadline from "../components/awards/AwardsHeadline";
import AwardsTabs from "../components/awards/AwardsTabs";
import AwardsDateFilters from "../components/awards/AwardsDateFilters";
import dayjs from "dayjs";
import {usePayAwardsData} from "../hooks/PayAwardsSearches";
import AwardsSectorSelection, {defaultPayAwardsLabel} from "../components/awards/AwardsSectorSelection";
import _ from "lodash";

const defaultFilters = {
    sort: "company_name",
    fromEffectiveDate: dayjs().subtract(12, "month").add(1, "day").format("YYYY-MM-DD"),
    toEffectiveDate: dayjs().format("YYYY-MM-DD"),
    increase: {min: 0, max: 30},
    increaseType: "All",
    ignoredAwards: [],
    unionRecognised: false,
    longTermDeals: false,
    employeeCount: {},
};

const PayAwards = () => {
    const [payAwards, setPayAwards] = useState({rows: []});
    const [filters, setFilters] = useState({...defaultFilters});
    const [bouncedFilters, setBouncedFilters] = useState({...defaultFilters});
    const [label, setLabel] = useState(defaultPayAwardsLabel);

    const updateBouncedFilters = useRef(_.debounce(async (updatedFilters) => {
        setBouncedFilters(updatedFilters);
    }, 1000)).current;

    const updateFilters = (update = filters) => {
        const updatedFilters = {...update};
        setFilters(updatedFilters);
        updateBouncedFilters(updatedFilters);
    };

    const {data, isLoading} = usePayAwardsData(bouncedFilters);
    useEffect(() => {
        if (!isLoading) {
            setPayAwards(data);
        }
    }, [data, isLoading]);

    const resetFilters = () => {
        updateFilters(defaultFilters);
        setLabel(defaultPayAwardsLabel);
    };

    return (
        <Stack>
            <HeaderBreadcrumb name="Pay Awards" color="payAwards" resetFilters={resetFilters}/>
            <Stack sx={{padding: '1rem'}} spacing={1}>
                <AwardsHeadline data={payAwards} label={label}/>
                <AwardsDateFilters filters={filters} updateFilters={updateFilters}/>
                <AwardsSectorSelection filters={filters} updateFilters={updateFilters} updateLabel={setLabel}/>
                <Stack direction="row" spacing={1}>
                    <AwardFilters filters={filters} updateFilters={updateFilters}/>
                    <AwardsTabs data={payAwards} label={label} filters={filters} updateFilters={updateFilters}/>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default PayAwards;
