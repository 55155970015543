import {Box, Grid2 as Grid, IconButton as MuiIconButton, Link as MuiLink, Paper as MuiPaper, Stack, styled, Typography as MuiTypography} from "@mui/material";
import {LinkedIn, Twitter} from "@mui/icons-material";

const Paper = styled(MuiPaper)(({theme}) => ({
    backgroundColor: theme.palette.admin.main,
    color: theme.palette.admin.contrastText,
    padding: '2rem 4rem 2rem 4rem',
}));

const Link = styled(MuiLink)(({theme}) => ({
    color: theme.palette.admin.contrastText,
    textDecoration: 'none',
    "&:hover": {
        color: theme.palette.payLevels.main,
    }
}));

const IconButton = styled(MuiIconButton)(({theme}) => ({
    color: theme.palette.admin.contrastText,
    "&:hover": {
        color: theme.palette.payLevels.main,
    }
}));

const Typography = styled(MuiTypography)(() => ({
    fontSize: "13px",
    height: "16px",
}));

const Footer = ({sticky = false}) => {

    let styles = {
        width: "100%",
    };

    if (sticky) {
        styles = {
            ...styles,
            marginTop: "auto",
            position: "fixed",
            bottom: "0",
        };
    }

    return (
        <Box component="footer" sx={styles}>
            <Paper elevation={0} square>
                <Stack>
                    <Grid container direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
                        <Grid size={11} sx={{flexGrow: 1}}>
                            <Stack direction="row" spacing={6}>
                                <Link fontSize="small" href="https://www.incomesdataresearch.co.uk/contact">Contact</Link>
                                <Link fontSize="small" href="https://www.incomesdataresearch.co.uk/privacy-policy">Website Privacy Policy</Link>
                                <Link fontSize="small" href="https://www.incomesdataresearch.co.uk/terms-and-conditions">Terms & Conditions for Subscriptions</Link>
                            </Stack>
                        </Grid>
                        <Grid size={1}>
                            <Stack direction="row" spacing={0}>
                                <IconButton href="https://www.linkedin.com/company/incomes-data-research/">
                                    <LinkedIn/>
                                </IconButton>
                                <IconButton href="https://twitter.com/payclimate">
                                    <Twitter/>
                                </IconButton>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Stack direction="row" spacing={6} sx={{ paddingTop: "1rem", borderTop: "2px solid rgba(255, 255, 255, 0.2)"}}>
                        <Stack>
                            <Typography>Incomes Data Research Limited</Typography>
                            <Typography>71-75 Shelton Street,</Typography>
                            <Typography>Covent Garden,</Typography>
                            <Typography>London, WC2H 9JQ</Typography>
                        </Stack>

                        <Stack>
                            <Typography>Registered in England & Wales</Typography>
                            <Typography>Company Number: 09327550</Typography>
                            <Typography>VAT Number: 201 3872 45</Typography>
                        </Stack>

                        <Stack>
                            <Typography>Copyright © 2025 Incomes Data Research. All rights reserved.</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Paper>
        </Box>
    );
}

export default Footer;
