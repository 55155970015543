import {Button, Stack, Typography} from "@mui/material";
import LevelsDataGrid from "./LevelsDataGrid";
import {useState} from "react";
import LevelBarChart from "./LevelBarChart";
import OrganisationDataGrid from "./OrganisationDataGrid";
import {payLevelsExport} from "../../hooks/PayLevelsSearches";
import {useAuth} from "../../auth/AuthProvider";
import {ExportLink, Tab, TabPanel, Tabs} from "../Tabs";

const labels = [
    "Job Level",
    "Region",
    "Sector / Industry",
];

const viewBy = [
    "levels",
    "regions",
    "sectors",
];

const LevelTabs = ({data, setViewBy, filters, updateFilters}) => {
    const {token} = useAuth();
    const [activeTab, setActiveTab] = useState(0);
    const [activeGridTab, setActiveGridTab] = useState(0);

    const selectTab = (event, newTab) => {
        setActiveTab(newTab);
        setViewBy(viewBy[newTab]);
    };

    const selectGridTab = (event, newGridTab) => {
        setActiveGridTab(newGridTab);
    };

    const exportData = () => {
        payLevelsExport(token, filters, viewBy[activeTab]).then((response) => {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'payLevels.csv');
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })
    };

    return (
        <Stack sx={{width: "100%"}}>
            <Tabs value={activeTab} onChange={selectTab} variant="scrollable">
                <Tab id="tab-0" label={labels[0]}/>
                <Tab id="tab-1" label={labels[1]}/>
                <Tab id="tab-2" label={labels[2]}/>
            </Tabs>

            <TabPanel index={0} activeTab={activeTab}>
                <LevelBarChart data={data} label={labels[0]}/>
            </TabPanel>
            <TabPanel index={1} activeTab={activeTab}>
                <LevelBarChart data={data} label={labels[1]}/>
            </TabPanel>
            <TabPanel index={2} activeTab={activeTab}>
                <LevelBarChart data={data} label={labels[2]}/>
            </TabPanel>

            <Stack direction="row" alignItems="center">
                <Typography>Summary | </Typography>
                <Button size="small" onClick={exportData}><ExportLink>Export</ExportLink></Button>
            </Stack>

            <Tabs value={activeGridTab} onChange={selectGridTab} variant="scrollable">
                <Tab id="tab-results" label="Summary Table"/>
                <Tab id="tab-organisation" label="Organisation Table"/>
            </Tabs>

            <TabPanel index={0} activeTab={activeGridTab}>
                <LevelsDataGrid data={data} firstColumn={labels[activeTab]}/>
            </TabPanel>
            <TabPanel index={1} activeTab={activeGridTab}>
                <OrganisationDataGrid filters={filters} updateFilters={updateFilters}/>
            </TabPanel>
        </Stack>
    );
};

export default LevelTabs;
