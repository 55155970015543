import {Button, Stack, Typography} from "@mui/material";
import AwardsDataGrid from "./AwardsDataGrid";
import {useState} from "react";
import AwardsTrendLine from "./AwardsTrendLine";
import AwardsTrendBarChart from "./AwardsTrendBarChart";
import AwardsDistHistogram from "./AwardsDistHistogram";
import AwardsDistScatter from "./AwardsDistScatter";
import AwardsIncreaseTypePie from "./AwardsIncreaseTypePie";
import YesNoPieChart from "./YesNoPieChart";
import {payAwardsExport} from "../../hooks/PayAwardsSearches";
import {useAuth} from "../../auth/AuthProvider";
import {ExportLink, Tab, TabPanel, Tabs} from "../Tabs";

const AwardsTabs = ({data, label, filters, updateFilters}) => {
    const {token} = useAuth();
    const [activeTab, setActiveTab] = useState(0);

    const selectTab = (event, newTab) => {
        setActiveTab(newTab);
    };

    const exportData = () => {
        payAwardsExport(token, filters).then((response) => {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'payAwards.csv');
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })
    };

    return (
        <Stack sx={{width: "100%"}}>
            <Stack direction="row" alignItems="center">
                <Typography>Results | </Typography>
                <Button size="small" onClick={exportData}><ExportLink>Export</ExportLink></Button>
            </Stack>
            <Tabs value={activeTab} onChange={selectTab} variant="scrollable">
                <Tab id="tab-0" label="Data"/>
                <Tab id="tab-1" label="Trend Line"/>
                <Tab id="tab-2" label="Trend Bar"/>
                <Tab id="tab-3" label="Dist. Scatter"/>
                <Tab id="tab-4" label="Dist. Histogram"/>
                <Tab id="tab-5" label="Type"/>
                <Tab id="tab-6" label="Union Rec."/>
                <Tab id="tab-7" label="Long-Term Deals"/>
            </Tabs>

            <TabPanel index={0} activeTab={activeTab}>
                <AwardsDataGrid data={data} filters={filters} updateFilters={updateFilters}/>
            </TabPanel>
            <TabPanel index={1} activeTab={activeTab}>
                <AwardsTrendLine data={data} label={label} filters={filters}/>
            </TabPanel>
            <TabPanel index={2} activeTab={activeTab}>
                <AwardsTrendBarChart data={data} label={label} filters={filters}/>
            </TabPanel>
            <TabPanel index={3} activeTab={activeTab}>
                <AwardsDistScatter data={data} label={label}/>
            </TabPanel>
            <TabPanel index={4} activeTab={activeTab}>
                <AwardsDistHistogram data={data} label={label}/>
            </TabPanel>
            <TabPanel index={5} activeTab={activeTab}>
                <AwardsIncreaseTypePie data={data} label={label}/>
            </TabPanel>
            <TabPanel index={6} activeTab={activeTab}>
                <YesNoPieChart data={data} label={label} field={'unionRecognised'}/>
            </TabPanel>
            <TabPanel index={7} activeTab={activeTab}>
                <YesNoPieChart data={data} label={label} field={"longTermDeal"}/>
            </TabPanel>
        </Stack>
    );
};

export default AwardsTabs;
