import './App.css';
import {Box} from "@mui/material";
import Header from "./components/Header";
import {Outlet} from "react-router";
import Footer from "./components/Footer";

const App = ({stickyFooter = false}) => {
    return (
        <Box sx={{flexGrow: 1}}>
            <Header/>
            <Outlet/>
            <Footer sticky={stickyFooter}/>
        </Box>
    );
}

export default App;
