import {
    Checkbox as MuiCheckbox,
    Collapse,
    List as MuiList,
    ListItem,
    ListItemButton as MuiListItemButton,
    ListItemIcon as MuiListItemIcon,
    ListItemText as MuiListItemText,
    styled,
    Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import _ from "lodash";
import {useRegions} from "../../hooks/ReferenceSearches";
import {addEntry, isSelected, removeEntry} from "../utils/FilterUtils";

export const List = styled(MuiList)(({theme}) => ({
    background: theme.palette.background.default,
    paddingTop: 0,
    paddingBottom: 0,
}));

export const ListItemButton = styled(MuiListItemButton)(() => ({
    padding: "0 0.5rem 0 0.5rem",
}));

export const ListItemText = styled(MuiListItemText)(() => ({
    "& .MuiListItemText-primary": {
        fontSize: "12.5px",
    }
}));

export const ListItemIcon = styled(MuiListItemIcon)(() => ({
    minWidth: 0,
    paddingRight: "0.5rem",
}));

export const Checkbox = styled(MuiCheckbox)(() => ({
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
    '& .MuiSvgIcon-root': {
        fontSize: "1.1rem"
    },
}));

export const Label = styled(Typography)(() => ({
    fontSize: "12.5px",
}));

const filtered_regions = "regions";

const RegionTree = ({filters, updateFilters}) => {
    const [regionOptions, setRegionOptions] = useState([]);

    const {regions, isLoading} = useRegions();
    useEffect(() => {

        if (!isLoading) {
            let options = [];
            regions.forEach(region => {
                if (region.id > 0) {
                    options.push(region);
                }
                // Remove the "Other" parent and treat its children as first class options
                if (region.id <= 0 && region.children?.length > 0) {
                    options = _.concat(options, region.children);
                }
            });

            setRegionOptions(options);
        }
    }, [regions, isLoading]);

    const handleNationalRate = () => {
        filters.includeNationalRate = !filters.includeNationalRate;
        updateFilters();
    };

    const allRegions = () => {
        return _.flatten(regionOptions.map((item) => [item, ...(item.children ? item.children : [])]))
    };

    const handleShowRegions = () => {
        filters.regions = showRegions() ? [] : allRegions().map(region => region.id);
        updateFilters();
    };

    const flipRegion = (region, filters, currentlySelected) => {
        if (currentlySelected) {
            removeEntry(region, filters, filtered_regions);
        } else {
            addEntry(region, filters, filtered_regions);
        }
    };

    const selectRegion = (event, region, parent) => {

        const currentlySelected = isSelected(region, filters, filtered_regions);
        flipRegion(region, filters, currentlySelected);

        // Children should be deselected if parent is deselected
        if (region.children?.length > 0) {
            region.children.forEach(child => {
                flipRegion(child, filters, currentlySelected);
            });
        }

        // Parent should be deselected if all children are deselected
        if (parent && parent.children) {
            const keepParent = _.reduce(parent.children.map(child => isSelected(child, filters, filtered_regions)), (selected, child) => selected || child, false);
            flipRegion(parent, filters, !keepParent);
        }

        updateFilters();
    };

    const showRegions = () => {
        return filters.regions?.length > 0 || false;
    };

    return (
        <List sx={{border: "1px solid rgb(204, 204, 204)"}}>
            <ListItem disablePadding>
                <ListItemButton onClick={handleShowRegions}>
                    <ListItemIcon>
                        <Checkbox edge="start" checked={!showRegions()} tabIndex={-1} disableRipple/>
                    </ListItemIcon>
                    <ListItemText>Overview</ListItemText>
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton onClick={handleNationalRate}>
                    <ListItemIcon>
                        <Checkbox edge="start" checked={filters.includeNationalRate} tabIndex={-1} disableRipple/>
                    </ListItemIcon>
                    <ListItemText>National Rate</ListItemText>
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton onClick={handleShowRegions}>
                    <ListItemIcon>
                        <Checkbox edge="start" checked={showRegions()} tabIndex={-1} disableRipple/>
                    </ListItemIcon>
                    <ListItemText>Regional Breakdown</ListItemText>
                </ListItemButton>
            </ListItem>

            <Collapse in={showRegions()} unmountOnExit>
                {regionOptions.map((region) => (
                    <List key={region.id}>
                        <ListItem disablePadding sx={{paddingLeft: "1rem"}}>
                            <ListItemButton onClick={(event) => selectRegion(event, region)}>
                                <ListItemIcon>
                                    <Checkbox edge="start" checked={isSelected(region, filters, filtered_regions)} tabIndex={-1} disableRipple/>
                                </ListItemIcon>
                                <ListItemText>{region.name}</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <List>
                            {region.children?.map((child) => (
                                <ListItem key={child.id} disablePadding sx={{paddingLeft: "2rem"}}>
                                    <ListItemButton onClick={(event) => selectRegion(event, child, region)}>
                                        <ListItemIcon>
                                            <Checkbox edge="start" checked={isSelected(child, filters, filtered_regions)} tabIndex={-1} disableRipple/>
                                        </ListItemIcon>
                                        <ListItemText><Label noWrap>{child.name}</Label></ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </List>
                ))}
            </Collapse>
        </List>
    );
};

export default RegionTree;
