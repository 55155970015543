export const isSelected = (entry, filters, field) => {
    return filters[field]?.includes(entry.id) || false;
};

export const addEntry = (entry, filters, field) => {
    filters[field].push(entry.id);
};

export const removeEntry = (entry, filters, field) => {
    filters[field] = filters[field].filter(item => item !== entry.id);
};

export const flipEntry = (entry, filters, field) => {
    const currentlySelected = isSelected(entry, filters, field);
    if (currentlySelected) {
        removeEntry(entry, filters, field);
    } else {
        addEntry(entry, filters, field);
    }
};

export const selectedEntriesOnly = (list, filters, field) => {
    const selected = filters[field] || [];
    return list?.filter(item => selected.includes(item.id)) || [];
};

export const deleteEntries = (filters, clearFields) => {
    clearFields.forEach(field => {
        delete filters[field];
    });
};
