import React from 'react';
import {AppBar as MuiAppBar, Box, IconButton as MuiIconButton, Link, Stack, styled, Toolbar, Tooltip, Typography} from '@mui/material';
import {useAuth} from "../auth/AuthProvider";
import {AccountCircle, Help, Logout} from "@mui/icons-material";
import {useLocation, useNavigate} from "react-router";

const AppBar = styled(MuiAppBar)(({theme}) => ({
    backgroundColor: theme.palette.background.paper,
}));

const Title = styled(Typography)(({theme}) => ({
    fontSize: theme.typography.h3.fontSize,
    fontWeight: 'bold',
    color: theme.palette.admin.main,
}));

const IconButton = styled(MuiIconButton)(({theme}) => ({
    color: theme.palette.payLevels.main,
    "&:hover": {
        color: theme.palette.admin.main,
    }
}));

const Header = () => {
    const {user, signOut} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = () => {
        signOut();
    };

    const isPayLevelsPage = location.pathname === "/levels";
    const isPayAwardsPage = location.pathname === "/awards";

    return (
        <AppBar position="static" elevation={0}>
            <Toolbar>
                <Stack direction="row" spacing={5} alignItems="center">
                    <Link href="https://incomesdataresearch.co.uk">
                        <img alt="Incomes Data Reserach" src="/assets/images/logo.svg?v=2.0.6" height="52" width="149"/>
                    </Link>
                    <Link href="/" underline="none">
                        <Title>Pay Benchmarker</Title>
                    </Link>
                </Stack>
                <Box sx={{flexGrow: 1}}>
                    <Stack direction="row" spacing={1} sx={{justifyContent: "right"}}>
                        {user && (
                            <Box>
                                <Tooltip title="My Details">
                                    <IconButton size="large" onClick={() => navigate("/my_details")}>
                                        <AccountCircle fontSize="large"/>
                                    </IconButton>
                                </Tooltip>
                                {isPayLevelsPage && (
                                    <Tooltip title="Glossary">
                                        <IconButton tooltip="Glossary" onClick={() => navigate("/help/levels")}>
                                            <Help fontSize="large"/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {isPayAwardsPage && (
                                    <Tooltip title="Glossary">
                                        <IconButton tooltip="Glossary" onClick={() => navigate("/help/awards")}>
                                            <Help fontSize="large"/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {!isPayLevelsPage && !isPayAwardsPage && (
                                    <Tooltip title="Help">
                                        <IconButton tooltip="Help" onClick={() => navigate("/help")}>
                                            <Help fontSize="large"/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <Tooltip title={'Logout'}>
                                    <IconButton onClick={handleLogout}>
                                        <Logout fontSize="large"/>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}
                    </Stack>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
