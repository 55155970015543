import {Divider, FormControlLabel, Paper as MuiPaper, Stack, styled, Switch, Typography} from "@mui/material";
import SliderWithEdit from "../SliderWithEdit";
import EmployeeCountFilter from "./EmployeeCountFilter";
import Select from "react-select";

const Paper = styled(MuiPaper)(({theme}) => ({
    background: theme.palette.payAwards.light,
    borderColor: theme.palette.contrastGrey.main,
}));

const FilterLabel = styled(Typography)(({theme}) => ({
    font: theme.typography.subtitle2.font,
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: 2,
}));

const increaseTypes = [
    {value: 'All', label: 'All'},
    {value: 'General', label: 'General'},
    {value: 'Gen+Merit', label: 'Gen+Merit'},
    {value: 'Merit', label: 'Merit'},
];

const AwardFilters = ({filters, updateFilters}) => {

    const handleIncreaseChange = (low, high) => {
        filters.increase = {min: low, max: high};
        updateFilters();
    };

    const handleIncreaseTypeChange = (selectedIncreaseType) => {
        filters.increaseType = selectedIncreaseType.value;
        updateFilters();
    };

    const handleEmployeeCountChange = (low, high) => {
        filters.employeeCount = {min: low, max: high};
        updateFilters();
    };

    const handleUnionRecognisedChange = (event) => {
        filters.unionRecognised = event.target.checked;
        updateFilters();
    };

    const handleLongTermDealsChange = (event) => {
        filters.longTermDeals = event.target.checked;
        updateFilters();
    };

    const increaseType = increaseTypes.find(type => type.value === filters.increaseType) || increaseTypes[0];

    return (
        <Paper variant="outlined">
            <Stack spacing={2} sx={{height: "100%", padding: "1rem"}}>
                <Typography variant="h6">Filters</Typography>

                <Stack>
                    <FilterLabel>Increase range</FilterLabel>
                    <SliderWithEdit
                        low={filters.increase.min}
                        high={filters.increase.max}
                        min={0}
                        max={30}
                        step={0.1}
                        fixed={1}
                        updateValues={handleIncreaseChange}
                    />
                </Stack>
                <Divider/>

                <Stack>
                    <FilterLabel>Type of increase</FilterLabel>
                    <Select
                        id="increase-type-select"
                        options={increaseTypes}
                        value={increaseType}
                        onChange={handleIncreaseTypeChange}
                    />
                </Stack>
                <Divider/>

                <Stack>
                    <FilterLabel>Number of employees</FilterLabel>
                    <EmployeeCountFilter low={filters.employeeCount.min} high={filters.employeeCount.max} updateFilters={handleEmployeeCountChange}/>
                </Stack>
                <Divider/>

                <FormControlLabel label="Union recognised" control={
                    <Switch
                        id="union-recognised-switch"
                        checked={filters.unionRecognised}
                        onChange={handleUnionRecognisedChange}
                        size="small"
                    />
                }/>
                <Divider/>

                <FormControlLabel label="Long-term deals" control={
                    <Switch
                        id="long-term-deals-switch"
                        checked={filters.longTermDeals}
                        onChange={handleLongTermDealsChange}
                        size="small"
                    />
                }/>
                <Divider/>
            </Stack>
        </Paper>
    );
};

export default AwardFilters;
