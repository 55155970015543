import {
    Checkbox,
    Collapse as MuiCollapse,
    IconButton,
    List as MuiList,
    ListItem,
    ListItemButton as MuiListItemButton,
    ListItemIcon,
    ListItemText,
    Paper as MuiPaper,
    Stack,
    styled
} from "@mui/material";
import {useCompanies, useIndustries, useSectors, useSubSectors} from "../../hooks/ReferenceSearches";
import {useEffect, useState} from "react";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {deleteEntries, flipEntry, isSelected, selectedEntriesOnly} from "../utils/FilterUtils";

const Box = styled(MuiPaper)(({theme}) => ({
    padding: "0.5rem",
    backgroundColor: theme.palette.contrastGrey.light,
}));

const Paper = styled(MuiPaper)(() => ({
    overflowX: 'auto',
    height: '9.5rem',
}));

const List = styled(MuiList)(({theme}) => ({
    background: theme.palette.background.default,
    paddingTop: 0,
    paddingBottom: 0,
}));

const ListItemButton = styled(MuiListItemButton)(({theme}) => ({
    padding: "0 0.5rem 0 0.5rem",
    '&.Mui-selected': {
        backgroundColor: theme.palette.payAwards.light,
    },
    '&:hover': {
        textDecoration: 'underline',
    }
}));

const Collapse = styled(MuiCollapse)(() => ({
    padding: "0 0 0 0.3rem",
}));

const numberRange = () => {
    return Array.from({length: 10}, (_, i) => i).map(i => ({id: `${i}`, name: `${i}`}));
};

const charRange = (length, start) => {
    return Array.from({length}, (_, i) => String.fromCharCode(start.charCodeAt(0) + i)).map(i => ({id: `${i}`, name: `${i}`}));
};

const alphaGroups = [
    {id: 1, name: "0-9", children: numberRange()},
    {id: 2, name: "A-E", children: charRange(5, 'A')},
    {id: 3, name: "F-J", children: charRange(5, 'F')},
    {id: 4, name: "K-O", children: charRange(5, 'K')},
    {id: 5, name: "P-T", children: charRange(5, 'P')},
    {id: 6, name: "U-Z", children: charRange(6, 'U')},
];

const defaultAlphaState = {open: false, groups: [...alphaGroups]};

const sectorLevel = [
    "sectors",
    "industries",
    "subSectors",
    "includeCompanies",
    "excludeCompanies",
    "ignoredAwards",
];

export const defaultPayAwardsLabel = "Whole UK Economy";

const generateLabel = (selectedParent, parentOptions, selectedChildren, childOptions) => {

    const parent = parentOptions?.filter(item => selectedParent?.includes(item.id))[0] || null;

    const parentLabel = Boolean(parent) ? parent.name : defaultPayAwardsLabel;
    const selected = childOptions?.filter(item => selectedChildren?.includes(item.id)) || [];

    if (selected.length === 0 || selected.length === childOptions.length) {
        return parentLabel;
    }

    if (selected.length === 1) {
        return selected[0].name;
    }

    return parentLabel + " (refined)";
};

const SelectableListItems = ({rows, selectedItems, showCheckBoxes = true, showArrow = true, canDisable = true, handleClick, handleSelect}) => {

    const clickHandler = (event, item) => {
        if (handleClick) {
            handleClick(item);
        }
        event.stopPropagation();
    };

    const selectHandler = (event, item) => {
        if (handleSelect) {
            handleSelect(item);
        }
        event.stopPropagation();
    };

    const selectedItem = selectedValue(selectedItems);

    const isSelected = (item) => selectedItems?.includes(item.id) || false;
    const isDisabled = () => selectedItems?.length <= 1;

    return (
        <>
            {rows?.map((item) => (
                <ListItem
                    key={item.id}
                    disablePadding
                    disableGutters
                    secondaryAction={showArrow && (
                        <IconButton edge="end" aria-label="open" onClick={(event) => clickHandler(event, item)}>
                            <ArrowRightIcon/>
                        </IconButton>
                    )}
                >
                    <ListItemButton
                        dense
                        divider
                        onClick={(event) => clickHandler(event, item)}
                        selected={selectedItem === item.id}
                    >
                        {showCheckBoxes && (
                            <ListItemIcon sx={{minWidth: 0, paddingRight: "0.5rem"}}>
                                <Checkbox sx={{paddingTop: 0, paddingBottom: 0, paddingRight: 0}}
                                          edge="start"
                                          checked={isSelected(item)}
                                          tabIndex={-1}
                                          disableRipple
                                          disabled={isDisabled() && canDisable}
                                          onClick={(event) => selectHandler(event, item)}
                                />
                            </ListItemIcon>
                        )}
                        <ListItemText primary={item.name}></ListItemText>
                    </ListItemButton>
                </ListItem>
            ))}
        </>
    );
};

const Section = ({open = true, rows, selectedItems, showCheckBoxes = true, showArrow = true, canDisable = true, handleClick, handleSelect}) => {
    return (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List>
                <SelectableListItems
                    rows={rows}
                    selectedItems={selectedItems}
                    showCheckBoxes={showCheckBoxes}
                    showArrow={showArrow}
                    canDisable={canDisable}
                    handleClick={handleClick}
                    handleSelect={handleSelect}
                />
            </List>
        </Collapse>
    );
};

const populateInitialSelection = (filters, field, values) => {
    if (filters[field] === undefined) {
        filters[field] = values?.map(item => item.id);
    }
};

const selectedValue = (list) => {
    return list?.length === 1 ? list[0] : null;
};

const AwardsSectorSelection = ({filters, updateFilters, updateLabel}) => {
    const [sectorOptions, setSectorOptions] = useState([]);
    const [industryOptions, setIndustryOptions] = useState([]);
    const [subSectorOptions, setSubSectorOptions] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);

    const {sectors} = useSectors();
    const {industries} = useIndustries(selectedValue(filters.sectors));
    const {subSectors} = useSubSectors(selectedValue(filters.industries));
    const {companies} = useCompanies(selectedValue(filters.subSectors), filters.alphaState?.selectedLetter);

    useEffect(() => {

        if (!filters.alphaState) {
            filters.alphaState = defaultAlphaState;
        }

        populateInitialSelection(filters, "sectors", sectors);
        populateInitialSelection(filters, "industries", industries);
        populateInitialSelection(filters, "subSectors", subSectors);
        populateInitialSelection(filters, "includeCompanies", companies);

        setSectorOptions(sectors);
        setIndustryOptions(industries);
        setSubSectorOptions(subSectors);
        setCompanyOptions(companies);
    }, [filters, sectors, industries, subSectors, companies]);

    const clearSectorSelection = () => {
        filters.alphaState = defaultAlphaState;
        deleteEntries(filters, sectorLevel);

        updateFilters();
        updateLabel(defaultPayAwardsLabel);
    };

    const handleClick = (item, fieldIndex) => {

        const field = sectorLevel[fieldIndex];
        const clearFields = sectorLevel.slice(fieldIndex + 1);

        filters.alphaState = defaultAlphaState;

        filters[field] = [item.id];
        deleteEntries(filters, clearFields);

        updateFilters();
    };

    const handleSelect = (item, list, fieldIndex, onClick) => {
        const field = sectorLevel[fieldIndex];

        flipEntry(item, filters, field);

        const filtered = selectedEntriesOnly(list, filters, field);
        if (filtered.length === 1) {
            onClick(filtered[0]);
        } else {
            filters[field] = filtered.map(element => element.id);
            updateFilters();
        }
    };

    const handleSectorClick = (sector) => {
        handleClick(sector, 0);
        updateLabel(generateLabel(null, null, filters.sectors, sectorOptions));
    };

    const handleSectorSelect = (sector) => {
        handleSelect(sector, sectorOptions, 0, handleSectorClick);
        updateLabel(generateLabel(null, null, filters.sectors, sectorOptions));
    };

    const handleIndustryClick = (industry) => {
        handleClick(industry, 1);
        updateLabel(generateLabel(filters.sectors, sectorOptions, filters.industries, industryOptions));
    };

    const handleIndustrySelect = (industry) => {
        handleSelect(industry, industryOptions, 1, handleIndustryClick);
        updateLabel(generateLabel(filters.sectors, sectorOptions, filters.industries, industryOptions));
    };

    const handleSubSectorClick = (subSector) => {
        handleClick(subSector, 2);
        updateLabel(generateLabel(filters.industries, industryOptions, filters.subSectors, subSectorOptions));
    };

    const handleSubSectorSelect = (subSector) => {
        handleSelect(subSector, subSectorOptions, 2, handleSubSectorClick);
        updateLabel(generateLabel(filters.industries, industryOptions, filters.subSectors, subSectorOptions));
    };

    const handleIncludeCompanyClick = (company) => {
        handleClick(company, 3);
        updateLabel(generateLabel(filters.subSectors, subSectorOptions, filters.includeCompanies, companyOptions));
    };

    const handleIncludeCompanySelect = (company) => {
        handleSelect(company, companyOptions, 3, handleIncludeCompanyClick);
        updateLabel(generateLabel(filters.subSectors, subSectorOptions, filters.includeCompanies, companyOptions));
    };

    const openAlphaGroups = () => {
        deleteEntries(filters, sectorLevel);
        filters.sectors = [];
        filters.alphaState = {...filters.alphaState, open: true};
        updateFilters();
    };

    const handleAlphaGroupClick = (group) => {
        deleteEntries(filters, sectorLevel.slice(3));
        filters.alphaState = {...filters.alphaState, selectedGroup: group, selectedLetter: null};
        updateFilters();
    };

    const handleAlphaLetterClick = (letter) => {
        deleteEntries(filters, sectorLevel.slice(3));
        filters.alphaState = {...filters.alphaState, selectedLetter: letter};
        updateFilters();
    };

    const handleExcludeCompanyClick = (company) => {
        filters.includeCompanies = [company.id];
        deleteEntries(filters, sectorLevel.slice(4));
        updateFilters();
        updateLabel(generateLabel(null, null, filters.includeCompanies, companyOptions));
    };

    const handleExcludeCompanySelect = (company) => {
        const field = sectorLevel[3];

        flipEntry(company, filters, field);

        const filtered = selectedEntriesOnly(companyOptions, filters, field);
        if (filtered.length === 1) {
            handleExcludeCompanyClick(filtered[0]);
        } else {
            filters.includeCompanies = companyOptions.filter(c => isSelected(c, filters, field)).map(c => c.id);
            filters.excludeCompanies = companyOptions.filter(c => !isSelected(c, filters, field)).map(c => c.id);
            deleteEntries(filters, sectorLevel.slice(5));
            updateFilters();
            updateLabel(generateLabel(null, null, filters.includeCompanies, companyOptions));
        }
    };

    return (
        <Box>
            <Paper elevation={0}>
                <Stack direction="row" spacing={0}>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton dense divider onClick={clearSectorSelection}>
                                <ListItemText inset primary="Whole UK Economy" sx={{paddingLeft: "29px"}}/>
                            </ListItemButton>
                        </ListItem>
                        <SelectableListItems
                            rows={sectorOptions}
                            selectedItems={filters.sectors}
                            handleClick={handleSectorClick}
                            handleSelect={handleSectorSelect}
                        />
                        <ListItem
                            disablePadding
                            disableGutters
                            secondaryAction={
                                <IconButton edge="end" aria-label="open">
                                    <ArrowRightIcon/>
                                </IconButton>
                            }
                        >
                            <ListItemButton dense divider onClick={openAlphaGroups} selected={filters.alphaState?.open}>
                                <ListItemText inset primary="A-Z Organisations" sx={{paddingLeft: "29px"}}/>
                            </ListItemButton>
                        </ListItem>
                    </List>

                    <Section
                        open={filters.alphaState?.open}
                        rows={filters.alphaState?.groups}
                        selectedItems={[filters.alphaState?.selectedGroup?.id]}
                        showCheckBoxes={false}
                        handleClick={handleAlphaGroupClick}
                    />

                    {filters.sectors?.length === 1 && (
                        <>
                            <Section
                                rows={industryOptions}
                                selectedItems={filters.industries}
                                handleClick={handleIndustryClick}
                                handleSelect={handleIndustrySelect}
                            />
                            {filters.industries?.length === 1 && (
                                <>
                                    <Section
                                        rows={subSectorOptions}
                                        selectedItems={filters.subSectors}
                                        handleClick={handleSubSectorClick}
                                        handleSelect={handleSubSectorSelect}
                                    />
                                    {filters.subSectors?.length === 1 && (
                                        <Section
                                            rows={companyOptions}
                                            selectedItems={filters.includeCompanies}
                                            showArrow={false}
                                            canDisable={false}
                                            handleClick={handleIncludeCompanyClick}
                                            handleSelect={handleIncludeCompanySelect}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    )}

                    {Boolean(filters.alphaState?.selectedGroup) && (
                        <>
                            <Section
                                rows={filters.alphaState?.selectedGroup.children}
                                selectedItems={[filters.alphaState?.selectedLetter?.id]}
                                showCheckBoxes={false}
                                handleClick={handleAlphaLetterClick}
                            />
                            {Boolean(filters.alphaState?.selectedLetter) && (
                                <Section
                                    rows={companyOptions}
                                    selectedItems={filters.includeCompanies}
                                    showArrow={false}
                                    canDisable={false}
                                    handleClick={handleExcludeCompanyClick}
                                    handleSelect={handleExcludeCompanySelect}
                                />
                            )}
                        </>
                    )}
                </Stack>
            </Paper>
        </Box>
    );
};

export default AwardsSectorSelection;
