import {Box} from "@mui/material";
import ScatterChart from "highcharts-react-official"
import Highcharts from "highcharts"
import {useEffect, useState} from "react";
import {formatCompanyName, generateChartTitle} from "./AwardChartUtils";
import {defaultChartOptions} from "../utils/ChartUtils";
import dayjs from "dayjs";

const chooseLabelFormat = (data) => {

    const startDate = dayjs(data.fromEffectiveDate);
    const endDate = dayjs(data.toEffectiveDate);
    const monthsDiff = endDate.diff(startDate, "month") + 1;

    if (monthsDiff > 61) {
        return "{value:%Y}";
    }

    if (monthsDiff > 18) {
        return "{value:%b %y}";
    }

    return "{value:%b}";
};

const AwardsDistScatter = ({data, label}) => {
    const [chartSeries, setChartSeries] = useState([]);
    const [chartTitle, setChartTitle] = useState('');
    const [chartLabelFormat, setChartLabelFormat] = useState('');

    useEffect(() => {
        const series = {};
        data.rows.forEach((row) => {
            const effectiveDate = dayjs(row.effectiveDate);
            const key = `"${row.sectorName}"`;
            const entry = series[key] || { name: row.sectorName, data: [] };
            series[key] = entry;
            entry.data.push({
                name: formatCompanyName(row.companyName),
                x: effectiveDate.toDate(),
                y: row.increase,
                formattedDate: effectiveDate.format("DD MMM YYYY"),
            });
        });

        setChartTitle(generateChartTitle(data, label));
        setChartSeries(Object.values(series));
        setChartLabelFormat(chooseLabelFormat(data));

    }, [data, label]);

    const options = {
        ...defaultChartOptions(chartTitle),
        chart: {
            type: "scatter",
        },
        xAxis: {
            type: "datetime",
            title: {
                text: "Effective Date",
            },
            labels: {
                format: chartLabelFormat,
            },
        },
        yAxis: {
            title: {
                text: "Increase %",
            }
        },
        tooltip: {
            headerFormat: '<span style="color:{point.color}">●</span> <span> {point.point.name}</span><br/>',
            pointFormat: 'Effective Date: <b>{point.formattedDate}</b> <br/> Increase: <b>{point.y}%</b>',
        },
        series: chartSeries,
    };

    return (
        <Box>
            <ScatterChart highcharts={Highcharts} options={options}/>
        </Box>
    );
};

export default AwardsDistScatter;
