import {Collapse, ListItem} from "@mui/material";
import {useEffect, useState} from "react";
import {useSectorsAndIndustries} from "../../hooks/ReferenceSearches";
import {Checkbox, Label, List, ListItemButton, ListItemIcon, ListItemText} from "./RegionTree"
import {flipEntry, isSelected, selectedEntriesOnly} from "../utils/FilterUtils";

const filters_sectors = "sectors";
const filters_industries = "industries";

const SectorTree = ({filters, updateFilters}) => {
    const [sectorOptions, setSectorOptions] = useState([]);

    const {sectors, isLoading} = useSectorsAndIndustries();
    useEffect(() => {
        if (!isLoading) {
            setSectorOptions(sectors);
        }
    }, [sectors, isLoading]);

    const handleShowSectors = () => {
        filters.sectors = showSectors() ? [] : sectorOptions.map(sector => sector.id);
        filters.industries = [];
        updateFilters();
    };

    const handleSectorClick = (event, sector) => {
        filters.sectors = [sector.id];
        filters.industries = sector.children.map(industry => industry.id);
        updateFilters();

        event.stopPropagation();
    };

    const handleSectorSelect = (event, sector) => {
        flipEntry(sector, filters, filters_sectors);

        const selected = selectedEntriesOnly(sectorOptions, filters, filters_sectors);
        if (selected.length === 0) {
            handleSectorClick(event, sector);
        } else if (selected.length === 1) {
            handleSectorClick(event, selected[0]);
        } else {
            filters.industries = [];
            updateFilters();
        }

        event.stopPropagation();
    };

    const handleIndustryClick = (event, sector, industry) => {
        filters.industries = [industry.id];
        updateFilters();

        event.stopPropagation();
    };

    const handleIndustrySelect = (event, sector, industry) => {
        flipEntry(industry, filters, filters_industries);

        const selected = selectedEntriesOnly(sector.children, filters, filters_industries);
        if (selected.length === 0) {
            handleIndustryClick(event, sector, industry);
        } else if (selected.length === 1) {
            handleIndustryClick(event, sector, selected[0]);
        } else {
            updateFilters();
        }

        event.stopPropagation();
    };

    const showIndustries = (sector) => {
        const selectedSectors = selectedEntriesOnly(sectorOptions, filters, filters_sectors);
        return isSelected(sector, filters, filters_sectors) && selectedSectors.length === 1;
    };

    const showSectors = () => {
        return filters.sectors?.length > 0 || false;
    };

    return (
        <List sx={{border: "1px solid rgb(204, 204, 204)"}}>
            <ListItem disablePadding>
                <ListItemButton onClick={handleShowSectors}>
                    <ListItemIcon>
                        <Checkbox edge="start" checked={!showSectors()} tabIndex={-1} disableRipple/>
                    </ListItemIcon>
                    <ListItemText>All Sectors and Industries</ListItemText>
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton onClick={handleShowSectors}>
                    <ListItemIcon>
                        <Checkbox edge="start" checked={showSectors()} tabIndex={-1} disableRipple/>
                    </ListItemIcon>
                    <ListItemText>Sector Breakdown</ListItemText>
                </ListItemButton>
            </ListItem>

            <Collapse in={showSectors()} timeout="auto" unmountOnExit>
                {sectorOptions?.map(sector => (
                    <List key={sector.id}>
                        <ListItem disablePadding sx={{paddingLeft: "1rem"}}>
                            <ListItemButton onClick={(event) => handleSectorClick(event, sector)}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={isSelected(sector, filters, filters_sectors)}
                                        tabIndex={-1}
                                        disableRipple
                                        onClick={(event) => handleSectorSelect(event, sector)}
                                    />
                                </ListItemIcon>
                                <ListItemText>{sector.name}</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <Collapse in={showIndustries(sector)} timeout="auto" unmountOnExit>
                            <List>
                                {sector.children?.map((industry) => (
                                    <ListItem key={industry.id} disablePadding sx={{paddingLeft: "2rem"}}>
                                        <ListItemButton onClick={(event) => handleIndustryClick(event, sector, industry)}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={isSelected(industry, filters, filters_industries)}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    onClick={(event) => handleIndustrySelect(event, sector, industry)}
                                                />
                                            </ListItemIcon>
                                            <ListItemText><Label noWrap>{industry.name}</Label></ListItemText>
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </Collapse>
                    </List>
                ))}
            </Collapse>

        </List>
    );
};

export default SectorTree;
