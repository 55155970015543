import {Link, Paper, Stack, styled, Typography} from "@mui/material";
import HeaderBreadcrumb from "../components/HeaderBreadcrumb";

const Section = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.admin.light,
    color: theme.palette.admin.contrastText,
}));

export const Title = styled(Typography)(({theme}) => ({
    padding: '0.5rem',
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightBold,
}));

export const HelpSection = styled(Stack)(() => ({
    padding: '0 0 0 1rem',
}));

export const HelpCaption = styled(Typography)(({theme}) => ({
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: 0,
    marginBottom: 0,
}));

export const HelpText = styled(Typography)(({theme}) => ({
    fontSize: theme.typography.body2.fontSize,
    paddingBottom: '0.5rem',
    "& dd": {
        display: 'list-item',
        listStyleType: 'disc',
    },
    "& dl": {
        marginTop: 0,
    }
}));

const Help = () => {
    return (
        <Stack>
            <HeaderBreadcrumb name="Help" color="admin"/>
            <Stack sx={{padding: '1rem'}} spacing={2}>
                <Paper variant="outlined" sx={{border: 'none'}}>
                    <Stack spacing={1}>
                        <Section elevation={0}>
                            <Title>Help</Title>
                        </Section>
                        <HelpSection>
                            <HelpCaption>Using the site</HelpCaption>
                            <HelpText>
                                There are help documents containing a glossary and details of the methodology used in each component of Pay Benchmarker.
                                Please access Pay Settlements, Pay Levels or Executive Compensation and select Help.
                            </HelpText>
                            <HelpText>
                                You can view and amend your personal information in the <Link href="/my_details">My Details</Link> section.
                            </HelpText>
                        </HelpSection>
                        <HelpSection>
                            <HelpCaption>Support</HelpCaption>
                            <HelpText>
                                If you are having trouble logging on or require further technical support, please contact Louisa Withers on +44 (0)7584 552178
                                or <Link href="mailto:enquiries@incomesdataresearch.co.uk">email</Link> us.
                            </HelpText>
                        </HelpSection>
                        <HelpSection>
                            <HelpCaption>Contact Us</HelpCaption>
                            <HelpText>
                                If you wish to provide us with your own pay settlement data or to speak to an editorial representative for the Pay Report, please
                                email: <Link href="mailto:enquiries@incomesdataresearch.co.uk">enquiries@incomesdataresearch.co.uk</Link>.
                            </HelpText>
                            <HelpText>
                                If you wish to provide us with your own salary levels data or to speak to an editorial representative for Pay Benchmark, please
                                email: <Link href="mailto:enquiries@incomesdataresearch.co.uk">enquiries@incomesdataresearch.co.uk</Link>.
                            </HelpText>
                            <HelpText>
                                If you wish to speak to an editorial representative for the Executive Compensation Review, please
                                email: <Link href="mailto:enquiries@incomesdataresearch.co.uk">enquiries@incomesdataresearch.co.uk</Link>.
                            </HelpText>
                            <HelpText>
                                To find out more about IDR services and products please
                                visit: <Link href="https://www.incomesdataresearch.co.uk" target="_blank" rel="noopener">www.incomesdataresearch.co.uk</Link>.
                            </HelpText>
                        </HelpSection>
                    </Stack>
                </Paper>
            </Stack>
        </Stack>
    );
};

export default Help;
