import {useAuth} from "../auth/AuthProvider";
import {Navigate} from "react-router";
import React from "react";

const ProtectedRoute = ({children}) => {
    const {user} = useAuth();
    return user ? children : <Navigate to="/" replace/>;
};

export default ProtectedRoute;
