import {Stack} from "@mui/material";
import HeaderBreadcrumb from "../components/HeaderBreadcrumb";
import LevelFilters from "../components/levels/LevelsFilters";
import {useEffect, useRef, useState} from "react";
import {usePayLevelsData} from "../hooks/PayLevelsSearches";
import LevelTabs from "../components/levels/LevelTabs";
import _ from "lodash";

const defaultFilters = {
    anonymous: false,
    includeNationalRate: false,
    regions: [],
    sectors: [],
    industries: [],
    salary: {
        min: 0,
        max: 150000,
    },
};

const PayLevels = () => {
    const [payLevels, setPayLevels] = useState({rows: []});
    const [viewBy, setViewBy] = useState("levels");
    const [filters, setFilters] = useState({...defaultFilters});
    const [bouncedFilters, setBouncedFilters] = useState({...defaultFilters});

    const updateBouncedFilters = useRef(_.debounce(async (updatedFilters) => {
        setBouncedFilters(updatedFilters);
    }, 1000)).current;

    const updateFilters = (update = filters) => {
        const updatedFilters = {...update};
        setFilters(updatedFilters);
        updateBouncedFilters(updatedFilters);
    };

    const {data, isLoading} = usePayLevelsData(bouncedFilters, viewBy);
    useEffect(() => {
        if (!isLoading) {
            setPayLevels(data);
        }
    }, [data, isLoading]);

    const resetFilters = () => {
        updateFilters(defaultFilters);
    };

    return (
        <Stack>
            <HeaderBreadcrumb name="Pay Levels" color="payLevels" resetFilters={resetFilters}/>
            <Stack sx={{padding: '1rem'}} spacing={1}>
                <Stack direction="row" spacing={1}>
                    <LevelFilters filters={filters} updateFilters={updateFilters}/>
                    <LevelTabs data={payLevels} setViewBy={setViewBy} filters={filters} updateFilters={updateFilters}/>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default PayLevels;
