import {Grid2 as Grid, Typography} from "@mui/material";
import {ProductCard, ProductCardWithLink} from "../components/ProductCard";
import LoginCard from "../components/LoginCard";
import {useAuth} from "../auth/AuthProvider";

const Home = () => {
    const {user} = useAuth();

    return (
        <Grid container spacing={2} sx={{padding: '2rem 4rem 0 4rem', borderTop: "1px solid rgba(0,0,0,0.1)"}}>
            {user ? (
                <Grid size={4}>
                    <ProductCardWithLink title="Pay Levels" link="/levels" color="payLevels">
                        <Typography variant="body2">
                            Pay levels by job title for named organisations throughout the UK. Data
                            includes basic pay, shift premiums, location allowances, hours and holidays.
                            Search by sector, industry and region. Generate instant analysis of medians,
                            upper and lower quartiles and average salaries by job title. Benchmark pay
                            rates against existing jobs and export your data in bespoke charts and tables.
                        </Typography>
                    </ProductCardWithLink>
                </Grid>
            ) : (
                <Grid size={4}>
                    <ProductCard title="What is Pay Benchmarker?" color="payLevels" showArrow={false}>
                        <Typography variant="body2">
                            Pay Benchmarker is an online benchmarking tool, giving you access to all of
                            the pay information collected by IDR. It is available on a 12-month
                            subscription, which can be extended to multiple users.
                        </Typography>
                        <Typography variant="body2">
                            What is it for?
                        </Typography>
                        <Typography variant="body2">
                            - Search and interrogate pay levels and pay awards
                        </Typography>
                        <Typography variant="body2">
                            - Export charts, graphs and tables of key information so you can compile reports and benchmark against your competitors
                        </Typography>
                    </ProductCard>
                </Grid>
            )}

            {user ? (
                <Grid size={4}>
                    <ProductCardWithLink title="Pay Awards" link="/awards" color="payAwards">
                        <Typography variant="body2">
                            The latest information on pay awards from named companies across the
                            economy. Search by company name, sector and industry. Refine by filtering
                            against employee numbers, range of increase, union recognition and type of
                            deal. View summary figures, analyse different time periods, track increases
                            against inflation and export data in bespoke charts and tables.
                        </Typography>
                    </ProductCardWithLink>
                </Grid>
            ) : (
                <Grid size={4}>
                    <ProductCard title="How to access Pay Benchmarker" color="payAwards" showArrow={false}>
                        <Typography variant="body2">
                            To request a free demonstration, please get in touch with us here.
                        </Typography>
                        <Typography variant="body2">
                            For any other queries email us at <a href="mailto:sales@incomesdataresearch.co.uk?subject=PayBenchmarker Demo Request">sales@incomesdataresearch.co.uk</a>.
                        </Typography>
                    </ProductCard>
                </Grid>
            )}

            {user ? (
                <Grid size={4}>
                    <ProductCard title="IDR Benchmarking Services" color="admin">
                        <Typography variant="body2">
                            IDR is experienced at benchmarking pay and benefits for a wide range of roles, both generic and
                            specialised, across the private, not-for-profit and public sectors.
                        </Typography>
                        <Typography variant="body2">
                            Our analysis ensures you are paying the amount necessary to recruit, retain and motivate employees.
                        </Typography>
                        <Typography variant="body2">
                            Call a member of the team to speak in more detail about our benchmarking services on +44(0)1702 669549.
                        </Typography>
                    </ProductCard>
                </Grid>
            ) : (
                <Grid size={4}>
                    <LoginCard color="admin"/>
                </Grid>
            )}
        </Grid>
    );
}

export default Home;
