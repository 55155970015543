import {DataGrid} from "@mui/x-data-grid";
import {useEffect, useState} from "react";
import {useOrganisations} from "../../hooks/PayLevelsSearches";

const formatSalary = (value) => {
    if (value) {
        return value.toLocaleString(undefined, {style: "currency", currency: "GBP"});
    }
    return "-";
};

const columns = [
    {
        field: "id",
        headerName: "ID",
        filterable: false,
    },
    {
        field: "organisation",
        headerName: "Organisation",
        flex: 0.8,
    },
    {
        field: "jobRole",
        headerName: "Job Role",
        flex: 0.8,
    },
    {
        field: "jobTitle",
        headerName: "Job Title",
        flex: 0.8,
    },
    {
        field: "jobLevel",
        headerName: "Job Level",
        headerAlign: "center",
        align: "center",
        flex: 0.3,
    },
    {
        field: "nationalRate",
        headerName: "Annual Salary",
        headerAlign: "right",
        align: "right",
        flex: 0.4,
        valueFormatter: (value) => formatSalary(value),
    },
    {
        field: "salaryMin",
        headerName: "Min",
        headerAlign: "right",
        align: "right",
        valueFormatter: (value) => formatSalary(value),
    },
    {
        field: "salaryMid",
        headerName: "Mid",
        headerAlign: "right",
        align: "right",
        valueFormatter: (value) => formatSalary(value),
    },
    {
        field: "salaryMax",
        headerName: "Max",
        headerAlign: "right",
        align: "right",
        valueFormatter: (value) => formatSalary(value),
    },
    {
        field: "hoursPerWeek",
        headerName: "Hours Per Week",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "paymentFrequency",
        headerName: "Pay Frequency",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "daysHoliday",
        headerName: "Holidays",
        headerAlign: "center",
        align: "center",
    },
    {
        field: "holidayComments",
        headerName: "Comments",
    },
    {
        field: "shifts",
        headerName: "Shifts",
    },
    {
        field: "extras",
        headerName: "Extras",
    },
    {
        field: "footnote",
        headerName: "Footnote",
    },
];

const OrganisationDataGrid = () => {
    const [organisations, setOrganisations] = useState([]);

    const {data, isLoading} = useOrganisations();

    useEffect(() => {
        if (!isLoading) {
            setOrganisations(data);
        }
    }, [data, isLoading]);

    const getTogglableColumns = (columns) => {
        return columns.filter((column) => column.field !== "id").map((column) => column.field);
    };

    return (
        <DataGrid
            rows={organisations}
            columns={columns}
            checkboxSelection
            disableRowSelectionOnClick
            density="compact"
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        id: false,
                        salaryMin: false,
                        salaryMid: false,
                        salaryMax: false,
                        hoursPerWeek: false,
                        paymentFrequency: false,
                        daysHoliday: false,
                        holidayComments: false,
                        shifts: false,
                        extras: false,
                        footnote: false,
                    },
                },
                pagination: {
                    paginationModel: {
                        pageSize: 20,
                    },
                },
                sorting: {
                    sortModel: [{field: 'organisation', sort: 'asc'}],
                },
            }}
            pageSizeOptions={[20]}
            slotProps={{
                columnsManagement: {
                    getTogglableColumns,
                },
            }}
            sx={{
                '& .MuiDataGrid-columnHeader': {
                    backgroundColor: 'contrastGrey.light',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                }
            }}
        />
    );
};

export default OrganisationDataGrid;
