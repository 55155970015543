import {Paper, Stack, styled} from "@mui/material";
import HeaderBreadcrumb from "../components/HeaderBreadcrumb";
import {HelpCaption, HelpSection, HelpText, Title} from "./Help";

const Section = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.payAwards.light,
}));

const AwardsGlossary = () => {
    return (
        <Stack>
            <HeaderBreadcrumb name="Help" color="payAwards" links={[{label: "Pay Awards", href: "/awards"}]}/>
            <Stack sx={{padding: '1rem'}} spacing={2}>
                <Paper variant="outlined" sx={{border: 'none'}}>
                    <Stack spacing={1}>
                        <Section elevation={0}>
                            <Title>Help - Pay Awards</Title>
                        </Section>
                        <HelpSection>
                            <HelpCaption>Pay data and type of increases</HelpCaption>
                            <HelpText>
                                Pay Benchmarker collates information on pay review outcomes across the economy and is continually updated by a team of specialist researchers. We collect information
                                from named organisations across a wide range of sectors, gathering information directly from HR managers (and sometimes from trade union negotiators, though usually in
                                specific circumstances, eg industry-wide deals or some public sector awards).
                            </HelpText>
                            <HelpText>
                                We record information on the percentage pay rise that most accurately represents what a typical employee receives. The figure recorded is the consolidated increase to
                                basic pay. Whilst we do record information on one-off payments and bonuses, we exclude these from our aggregate figures on pay awards.
                            </HelpText>
                            <HelpText>
                                Where an increase is partly or wholly based on performance, resulting in a range of increases, we record the average percentage rise as a merit or general plus merit
                                award. Around a quarter of deals in our online database are either entirely or partially based on individual merit. This approach is particularly prevalent in sectors
                                such as financial services.
                            </HelpText>
                        </HelpSection>

                        <HelpSection>
                            <HelpCaption>Staged increases</HelpCaption>
                            <HelpText>
                                Staging an increase over the course of a year means that employees receive less than the full annual value of the award than if it were paid in one go. Where there is a
                                staged increase, for example, 2.5% from April, and a further 1% from October of the same year, our practice is to just include the first stage of the deal in our
                                aggregate figures, to avoid double-counting the employee group and/or numbers. The second stage is still recorded but excluded from the aggregate figures. We try to
                                avoid calculating an annualised figure as this can result in recording a figure that no employee actually received, and we also try to avoid double-counting any award.
                            </HelpText>
                        </HelpSection>

                        <HelpSection>
                            <HelpCaption>Flat-rate rises</HelpCaption>
                            <HelpText>
                                Flat-rate rises which are consolidated into basic salary, eg £500 instead of a percentage increase to pay, are not recorded in the database so these increases are
                                excluded from the aggregate figures.
                            </HelpText>
                        </HelpSection>

                        <HelpSection>
                            <HelpCaption>Pay pauses and pay freezes</HelpCaption>
                            <HelpText>
                                A 'pay freeze' is where no increase is paid from the annual pay review date, or for at least six months after this. Pay freezes are recorded as zero and are included in
                                the aggregate figures. Pay pauses, where a review is deferred for a period of less than six months after the usual review date and then an increase is subsequently
                                paid, are not recorded in our database.
                            </HelpText>
                        </HelpSection>

                        <HelpSection>
                            <HelpCaption>List of sectors monitored</HelpCaption>
                            <HelpText component="div">
                                <dl>
                                    <dt>Manufacturing & primary</dt>
                                    <dd>Agriculture & forestry</dd>
                                    <dd>Chemicals, pharmaceuticals & oil</dd>
                                    <dd>Construction</dd>
                                    <dd>Energy & water</dd>
                                    <dd>Engineering</dd>
                                    <dd>Engineering: Aerospace, defence & shipbuilding</dd>
                                    <dd>Engineering: Electronics</dd>
                                    <dd>Engineering: General</dd>
                                    <dd>Engineering: Rail infrastructure</dd>
                                    <dd>Engineering: Shipbuilding</dd>
                                    <dd>Engineering: Vehicles & components</dd>
                                    <dd>Food, drink & tobacco</dd>
                                    <dd>Mining & quarrying</dd>
                                    <dd>Glass, pottery & ceramics</dd>
                                    <dd>Paper & packaging</dd>
                                    <dd>Textiles, clothing & footwear</dd>
                                    <dd>Timber & furniture</dd>
                                    <dd>Other manufacturing</dd>
                                </dl>

                                <dl>
                                    <dt>Private services</dt>
                                    <dd>Business services</dd>
                                    <dd>Financial services</dd>
                                    <dd>Hotels, restaurants, arts & leisure</dd>
                                    <dd>Fast food, pubs & restaurants</dd>
                                    <dd>Hotels</dd>
                                    <dd>Leisure</dd>
                                    <dd>IT, telecoms & media</dd>
                                    <dd>IT</dd>
                                    <dd>Media</dd>
                                    <dd>Telecommunications</dd>
                                    <dd>Professional & business services</dd>
                                    <dd>Business services</dd>
                                    <dd>Call centres</dd>
                                    <dd>Catering</dd>
                                    <dd>Cleaning</dd>
                                    <dd>Domestic services</dd>
                                    <dd>Employment services</dd>
                                    <dd>Engineering services</dd>
                                    <dd>Health & care</dd>
                                    <dd>Pharmaceutical services</dd>
                                    <dd>Property</dd>
                                    <dd>Recruitment</dd>
                                    <dd>Rental & leasing</dd>
                                    <dd>Security</dd>
                                    <dd>Storage</dd>
                                    <dd>Waste management</dd>
                                    <dd>Retail & wholesale</dd>
                                    <dd>Retail</dd>
                                    <dd>Wholesale</dd>
                                    <dd>Transport, storage & distribution</dd>
                                    <dd>Transport: Air</dd>
                                    <dd>Transport: Buses</dd>
                                    <dd>Transport: Rail</dd>
                                    <dd>Transport: Road & distribution</dd>
                                    <dd>Transport: Shipping & ports</dd>
                                </dl>

                                <dl>
                                    <dt>Not-for-profit & related services</dt>
                                    <dd>Membership, research & regulatory</dd>
                                    <dd>Research councils/institutes & research funds</dd>
                                    <dd>Trade unions & professional associations</dd>
                                    <dd>Other membership, research & regulatory</dd>
                                    <dd>Nurseries, housing & welfare services</dd>
                                    <dd>Advice, campaigning & aid</dd>
                                    <dd>Care services & housing</dd>
                                    <dd>Helplines, advice, counselling</dd>
                                    <dd>NGOs</dd>
                                    <dd>Nurseries</dd>
                                    <dd>Other nurseries, housing & welfare</dd>
                                    <dd>Recreation, culture & environment</dd>
                                    <dd>Culture</dd>
                                    <dd>Environment</dd>
                                    <dd>Recreation</dd>
                                </dl>

                                <dl>
                                    <dt>Public sector</dt>
                                    <dd>Central government</dd>
                                    <dd>Education</dd>
                                    <dd>Health</dd>
                                    <dd>Local government</dd>
                                    <dd>Other public services</dd>
                                    <dd>Fire & police</dd>
                                    <dd>Other public services</dd>
                                    <dd>Post office</dd>
                                </dl>
                            </HelpText>
                            <HelpText>
                                To select more than one Industry, use the checkboxes provided - you can only select multiple industries within your chosen sector.
                            </HelpText>
                        </HelpSection>

                        <HelpSection>
                            <HelpCaption>Private sector calculations</HelpCaption>
                            <HelpText>
                                To produce a private sector median, select both the manufacturing and primary and private services sectors.
                            </HelpText>
                        </HelpSection>

                        <HelpSection>
                            <HelpCaption>Calculations glossary</HelpCaption>
                            <HelpText>Average - the sum of all the individual records in a sample divided by the total number of records in that sample</HelpText>
                            <HelpText>Median - the middle number or average of the two middle numbers when a sample of data is placed in ascending order</HelpText>
                            <HelpText>Lower quartile - the value below which 25 per cent of values in a sample are lower and 75 per cent are higher</HelpText>
                            <HelpText>Upper quartile - the value below which 75 per cent of values in a sample are lower and 25 per cent are higher</HelpText>
                            <HelpText>
                                Weighted average - the weighted average is similar to an arithmetic average where instead of each of the data points contributing equally to the final average, some
                                data points contribute more than others. With pay award data, this means that increases which cover a larger number of employees are given an increased weight.
                            </HelpText>
                        </HelpSection>

                        <HelpSection>
                            <HelpCaption>Selecting time periods</HelpCaption>
                            <HelpText>
                                Our database allows you to search for 12-month or yearly figures, six-month and three-month rolling figures. In our analysis of pay awards (which appears quarterly in
                                our IDR Pay Climate bulletin) we report the three-month rolling summary figures. This provides the most current information on pay settlements. These rolling
                                three-month periods are set from the first day of the first month of the analysis to the last day of the third month, eg 1 January 2020 to 31 March 2020, and are
                                'rolled-on' at one-month intervals.
                            </HelpText>
                            <HelpText>
                                Annual figures provide a good overview of pay trends over the past year and allow for good comparisons, sector by sector. Yearly time periods are also useful for
                                analysis of sectors where there is limited settlement data at particular times of the year, for instance, the public sector. Pay reviews in the public sector typically
                                take place in April and August or September and so it would be difficult to calculate summary figures on a three-month rolling basis in parts of the year where these
                                months are not included.
                            </HelpText>
                            <HelpText>
                                If your search returns no data, it may be because the date range you have selected is too narrow. Consider selecting a wider date range where the summary figures will
                                be based on a greater number of pay awards.
                            </HelpText>
                        </HelpSection>

                        <HelpSection>
                            <HelpCaption>Exporting data - information fields available</HelpCaption>
                            <HelpText>
                                <dl>
                                    <dd>ID - every record has a unique ID for referencing purposes only</dd>
                                    <dd>Organisation name</dd>
                                    <dd>Subsector</dd>
                                    <dd>Employee group - this is decided at company level</dd>
                                    <dd>Award level - this refers to company, industry, voluntary or the public sector - the level at which the award was made</dd>
                                    <dd>Effective date</dd>
                                    <dd>Numbers - numbers of employees the pay award covers</dd>
                                    <dd>% Increase - the percentage pay rise that most accurately represents what a typical employee received (as described previously)</dd>
                                    <dd>
                                        Type of increase - this refers to either general, merit or general+merit. The former two refer to average or performance-related increases (as described
                                        previously)
                                    </dd>
                                    <dd>Long term deal - indicates whether or not this is - a Long Term Deal</dd>
                                    <dd>Stage of LTD - this indicates whether the long term deal is in its final stage, or if there are more stages</dd>
                                    <dd>LTD months - this number indicates how many months the settlement is effective for, if part of a long-term arrangement</dd>
                                    <dd>
                                        Year of deal - if the increase is part of a long-term deal this field states in which year of the settlement the increase is effective. For example, if this
                                        field states 1 and the LTD months field states 36, this indicates that the settlement is the first year of a three-year deal
                                    </dd>
                                    <dd>
                                        Staged - this indicates whether the increase is part of a staged pay award, with pay increases staged from different effective dates. For example, the first
                                        stage of a two-stage deal. First-stage increases are included in aggregate figures, while second stages are not, to avoid double-counting pay awards
                                    </dd>
                                    <dd>
                                        Deferred - this indicates a pay award that is part of a long-term deal has been deferred from its usual pay review date. Refer to the comments field for further
                                        details (or for information on one-year deals that may have been deferred)
                                    </dd>
                                    <dd>Comments - this explains more details of the pay award, if relevant/necessary</dd>
                                    <dd>Union recognised - this indicates if a trade union is involved in pay negotiations</dd>
                                    <dd>Union details - if a trade union is recognised, this field states which trade union(s) are involved in pay negotiations</dd>
                                    <dd>Bonus - this states that a bonus has been paid</dd>
                                    <dd>Bonus amount - amount of bonus paid out</dd>
                                    <dd>Bonus frequency - how often a bonus is paid, eg £pm or £pa</dd>
                                    <dd>Bonus criteria - who qualifies for a bonus and how</dd>
                                    <dd>
                                        RPI link - states whether the all-items Retail Price Index (RPI) inflation figure is linked to the pay increase. The RPI is the main inflation measure used in
                                        pay negotiations and figures particularly in the formulas for rises under long-term deals
                                    </dd>
                                    <dd>Formula - this field sets out the formula link to RPI inflation where relevant</dd>
                                    <dd>
                                        Increase to scales - this is for increases which include a basic percentage rise plus progression increases. This is particularly relevant for settlements in
                                        central government and the finance sector. A description of these types of increases will appear in the comments field
                                    </dd>
                                </dl>
                            </HelpText>
                        </HelpSection>

                        <HelpSection>
                            <HelpCaption>Reasons for no data in export fields</HelpCaption>
                            <HelpText>
                                Organisation name is different from initial search results - the online database only allows you to search for current or 'live' company names. So, if you search for an
                                old company name (ie. a company that was once referred to by another name before a take-over or merger with another company), the exported data will include the name of
                                the company at the time of that particular pay award
                            </HelpText>
                            <HelpText>
                                Increase field returns '0' - Lump-sum rises and other non-consolidated payments such as bonuses (without a pay increase) will be recorded in the database as '0' in the
                                increase field (not to be confused with pay freezes which are also recorded as zero in the increase field). The comments field will distinguish between pay freezes,
                                lump sum payments and bonuses. Lump sum payments are excluded from aggregate figures.
                            </HelpText>
                            <HelpText>
                                Union details are blank - if a trade union has been involved in negotiating the pay award (indicated by a 'TRUE' in the union recognition field), a blank return means
                                that the union name was not recorded
                            </HelpText>
                            <HelpText>Once data is exported, the system allows you to save your results to be manipulated for your own analysis and calculations.</HelpText>
                            <HelpText>Graphs - The online database allows you to create interactive graphs using various selections of data.</HelpText>
                            <HelpText>Trend line - this graph allows users to track levels of pay awards against RPI inflation</HelpText>
                            <HelpText>Trend bar - this shows the distribution of level of increases</HelpText>
                            <HelpText>
                                Distribution scatter - this graph illustrates the distribution of pay awards in a particular time period with each dot representing the pay rise for a particular
                                organisation or bargaining group. The month indicates the effective date of the increase
                            </HelpText>
                            <HelpText>Distribution histogram - this graph illustrates the level of pay awards over a particular timeframe</HelpText>
                            <HelpText>Increase type - this pie chart illustrates the type of pay awards, either general (basic increase covering all staff), merit or general plus merit</HelpText>
                            <HelpText>Union recognised - this pie chart shows the proportion of pay awards that are recognised by a trade union</HelpText>
                            <HelpText>
                                Long-term deals - this pie chart shows the proportion of pay awards that are part of long-term deals in a specific time period. It also shows the break-down of these
                                deals that have further stages to go and those that are in the final years of the deal
                            </HelpText>
                        </HelpSection>
                    </Stack>
                </Paper>
            </Stack>
        </Stack>
    );
};

export default AwardsGlossary;
