import {Paper, Stack, styled, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import HeaderBreadcrumb from "../components/HeaderBreadcrumb";
import {HelpCaption, HelpSection, HelpText, Title} from "./Help";

const Section = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.payLevels.light,
}));

const HeaderCell = styled(TableCell)(() => ({
    fontWeight: "bold",
}));

const LevelsGlossary = () => {
    return (
        <Stack>
            <HeaderBreadcrumb name="Help" color="payLevels" links={[{label: "Pay Levels", href: "/levels"}]}/>
            <Stack sx={{padding: '1rem'}} spacing={2}>
                <Paper variant="outlined" sx={{border: 'none'}}>
                    <Stack spacing={1}>
                        <Section elevation={0}>
                            <Title>Help - Pay Levels</Title>
                        </Section>
                        <HelpSection>
                            <HelpText>
                                The Pay Levels is designed to help you benchmark basic salary levels. You can search by sector, industry and region to generate instant analysis.
                            </HelpText>
                        </HelpSection>

                        <HelpSection>
                            <HelpCaption>Job Roles</HelpCaption>
                            <HelpText>
                                Search for a job role from our list of around 200 job titles contained in the IDR Pay Levels database. You can select more than one job title if required and the
                                results displayed provide aggregate pay levels for all job selected combined.
                            </HelpText>
                        </HelpSection>

                        <HelpSection>
                            <HelpCaption>Job Family</HelpCaption>
                            <HelpText>
                                You can explore Pay Levels by Job Family. Simply select a job family from the dropdown menu and view pay level data for that job family, i.e. Administration. The
                                default option is for all jobs within a job family to be selected. You can also narrow down your search by selecting or de-selecting individual jobs within your chosen
                                job family.
                            </HelpText>
                        </HelpSection>

                        <HelpSection>
                            <HelpCaption>Average and median pay rates</HelpCaption>
                            <HelpText>
                                Selecting a job title will initially show you the average, median and upper and lower quartile summary figures of the midpoint/market rate for all the jobs in our
                                database, both in named and anonymous organisations. Where there are jobs of different levels of skills and responsibility included under the same job title then the
                                market rate for each job level is included in the summary calculations.
                            </HelpText>
                        </HelpSection>

                        <HelpSection>
                            <HelpCaption>National Rate</HelpCaption>
                            <HelpText>
                                The National Rate summarises the selected sample by excluding any additions to pay associated with location. For example, London allowances are excluded from the
                                National Rate calculation. If an organisation pays different rates in different locations then the lowest rate is taken as the national rate, and only this rate goes
                                into the calculations. The figure is 'weighted' by the number of regions a specific role is employed in by each employer. ...a specific role is filled by each employer.
                            </HelpText>
                        </HelpSection>

                        <HelpSection>
                            <HelpCaption>All Regions</HelpCaption>
                            <HelpText>

                            </HelpText>
                        </HelpSection>

                        <HelpSection>
                            <HelpCaption></HelpCaption>
                            <HelpText>
                                The All Regions calculation includes all additional payments associated with location. For example, London allowances are included in this calculation. Where an
                                organisation varies the rate of pay by location then each unique market rate for each job in that organisation is included in the calculations.
                            </HelpText>
                        </HelpSection>

                        <HelpSection>
                            <HelpCaption>Calculations glossary</HelpCaption>
                            <HelpText>
                                Average - the sum of all the individual records in a sample divided by the total number of records in that sample
                            </HelpText>
                            <HelpText>
                                Median - the middle number or average of the two middle numbers when a sample of data is placed in ascending order
                            </HelpText>
                            <HelpText>
                                Lower quartile - the value below which 25 per cent of values in a sample are lower and 75 per cent are higher
                            </HelpText>
                            <HelpText>
                                Upper quartile - the value below which 75 per cent of values in a sample are lower and 25 per cent are higher
                            </HelpText>
                        </HelpSection>

                        <HelpSection>
                            <HelpCaption>Organisation Table</HelpCaption>
                            <HelpText>
                                You can view actual rates or salaries organisations are paying for a particular job by selecting the 'Organisation Table' tab. Where available the organisation table
                                shows the following information:
                            </HelpText>
                            <HelpText>
                                <dl>
                                    <dd>
                                        Calculated National Rate - this is the midpoint of the salary range or spot rate, depending on the organisation's pay structure, expressed as an annual amount.
                                        The calculated national rate is the figure that has been included in the aggregate market summary figures
                                    </dd>
                                    <dd>Min/Mid/Max - this is pay range minimum, midpoint and maximum for the job expressed as either annual, weekly or hourly amounts</dd>
                                    <dd>Job Level - this is the assigned IDR job level (see more on IDR job levels further on in this guide)</dd>
                                    <dd>Hours Per week - this provides the weekly contracted hours which usually exclude meal breaks (referred to as net hours by some organisations)</dd>
                                    <dd>Payment Frequency - indicates whether the basic rate is an annual, weekly or hourly amount</dd>
                                    <dd>Days Holiday entitlement - holiday entitlement in days</dd>
                                    <dd>Holiday comments - details of service-related holiday and other relevant notes</dd>
                                    <dd>
                                        Shift and overtime - abbreviated as follows: MS, AS, NS - morning, afternoon and night shift; ES = early shift, or sometimes evening shift, LS = late shift, DD
                                        = double day, BS = back shift, RS = rotating shift, Contl = continental, Cont and Discont = continuous and discontinuous shifts, 2S and 3S, two-shift and
                                        three-shift. A full list of abbreviations is given further down
                                    </dd>
                                    <dd>Extras - these include bonuses, profit-share schemes, performance-related pay, special allowances, lump sums, guaranteed payments etc</dd>
                                    <dd>Footnote - the starting date of a London Allowance or Large Town Allowance (LTA) may differ from that of the agreement as a whole.</dd>
                                    <dd>Company-specific Job Title - actual job title used at the organisation</dd>
                                </dl>
                            </HelpText>
                        </HelpSection>

                        <HelpSection>
                            <HelpCaption>List of abbreviations</HelpCaption>
                            <HelpText>
                                <Table size="small" sx={{width: '35%'}}>
                                    <TableRow>
                                        <TableCell>AS</TableCell>
                                        <TableCell>Afternoon shift</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Assim</TableCell>
                                        <TableCell>Assimilation</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>BS</TableCell>
                                        <TableCell>Back shift</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>CFM</TableCell>
                                        <TableCell>Continuous flow manufacturing</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>CoL</TableCell>
                                        <TableCell>Cost of living</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Consol</TableCell>
                                        <TableCell>Consolidated</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Cont</TableCell>
                                        <TableCell>Continuous</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Contl</TableCell>
                                        <TableCell>Continental</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>DD</TableCell>
                                        <TableCell>Double day</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Discont</TableCell>
                                        <TableCell>Discontinuous</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>DS</TableCell>
                                        <TableCell>Day shift</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>ES</TableCell>
                                        <TableCell>Early shift, or sometimes Evening shift</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>LA</TableCell>
                                        <TableCell>London allowance</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>LS</TableCell>
                                        <TableCell>Late shift</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>LTA</TableCell>
                                        <TableCell>Large town allowance</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>MEL</TableCell>
                                        <TableCell>Minimum earnings level</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>MS</TableCell>
                                        <TableCell>Morning shift</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>NMW</TableCell>
                                        <TableCell>National Minimum Wage</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>NS</TableCell>
                                        <TableCell>Night shift</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>O/n</TableCell>
                                        <TableCell>Overnight</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>O/t</TableCell>
                                        <TableCell>Overtime</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Perf-related</TableCell>
                                        <TableCell>Performance-related</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>PRP</TableCell>
                                        <TableCell>Profit-related pay</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>RS or Rot S</TableCell>
                                        <TableCell>Rotating shift</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Suppl</TableCell>
                                        <TableCell>Supplement</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>T</TableCell>
                                        <TableCell>Time</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>T+½</TableCell>
                                        <TableCell>Time-and-a-half</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>2T</TableCell>
                                        <TableCell>Double time</TableCell>
                                    </TableRow>
                                </Table>
                            </HelpText>
                        </HelpSection>

                        <HelpSection>
                            <HelpCaption>Regions</HelpCaption>
                            <HelpText>
                                You can narrow your search by region, so that if you are interested only in results for the North West or the South East you can extract those. The regions are divided
                                up as follows:
                            </HelpText>
                            <HelpText sx={{paddingLeft: '2rem'}}>
                                London
                                <dl>
                                    <dd>Central/Inner London</dd>
                                    <dd>Outer/Greater London (within M25)</dd>
                                </dl>
                            </HelpText>
                            <HelpText sx={{paddingLeft: '2rem'}}>
                                London Commuter Belt (LCB)
                            </HelpText>
                            <HelpText sx={{paddingLeft: '4rem'}}>
                                London Commuter Belt refers to the fringe area outside London, where locations have a practical commute into the capital. For example:
                                <dl>
                                    <dd>Basildon</dd>
                                    <dd>Berkshire (Bracknell, Slough, Windsor, Maidenhead)</dd>
                                    <dd>Brentwood</dd>
                                    <dd>Chelmsford</dd>
                                    <dd>Chiltern</dd>
                                    <dd>Epping Forest</dd>
                                    <dd>Guildford</dd>
                                    <dd>Harlow</dd>
                                    <dd>Hertfordshire (Broxbourne, Dacorum, Hatfield, Hertsmere, St Albans, Stevenage, Watford, Welwyn Garden City)</dd>
                                    <dd>Kent (Sevenoaks, Orpington, Dartford)</dd>
                                    <dd>Thurrock</dd>
                                    <dd>West Sussex (Crawley, Gatwick)</dd>
                                </dl>
                                If you'd like to know more about the background to this area or have any further questions please contact the team via the 'Contact Us' page on the IDR website.
                            </HelpText>
                            <HelpText sx={{paddingLeft: '2rem'}}>South East excluding London Commuter Belt</HelpText>
                            <HelpText sx={{paddingLeft: '2rem'}}>East of England</HelpText>
                            <HelpText sx={{paddingLeft: '2rem'}}>South West</HelpText>
                            <HelpText sx={{paddingLeft: '2rem'}}>West Midlands</HelpText>
                            <HelpText sx={{paddingLeft: '2rem'}}>East Midlands</HelpText>
                            <HelpText sx={{paddingLeft: '2rem'}}>Yorkshire and Humberside</HelpText>
                            <HelpText sx={{paddingLeft: '2rem'}}>North West</HelpText>
                            <HelpText sx={{paddingLeft: '2rem'}}>North East</HelpText>
                            <HelpText sx={{paddingLeft: '2rem'}}>Wales</HelpText>
                            <HelpText sx={{paddingLeft: '2rem'}}>Scotland</HelpText>
                            <HelpText sx={{paddingLeft: '2rem'}}>Northern Ireland</HelpText>
                        </HelpSection>

                        <HelpSection>
                            <HelpCaption>Sectors</HelpCaption>
                            <HelpText>
                                You can also narrow your search of jobs by the main sectors of manufacturing and primary, private services and not-for-profit services and and public sector services.
                                The online database covers the following sectors within the four main groupings:
                            </HelpText>
                            <HelpText>
                                <dl>
                                    <dt>Manufacturing & primary</dt>
                                    <dd>Agriculture & forestry</dd>
                                    <dd>Chemicals, pharmaceuticals & oil</dd>
                                    <dd>Construction</dd>
                                    <dd>Energy & water</dd>
                                    <dd>Engineering</dd>
                                    <dd>Food, drink & tobacco</dd>
                                    <dd>Mining & quarrying</dd>
                                    <dd>Other manufacturing</dd>
                                </dl>

                                <dl>
                                    <dt>Private services</dt>
                                    <dd>Financial services</dd>
                                    <dd>Hotels, restaurants, arts & leisure</dd>
                                    <dd>IT, telecoms, media</dd>
                                    <dd>Professional & business services</dd>
                                    <dd>Rental & leasing</dd>
                                    <dd>Retail & wholesale</dd>
                                    <dd>Transport, storage & distribution</dd>
                                </dl>

                                <dl>
                                    <dt>Not-for-profit & related services</dt>
                                    <dd>Membership, research & regulatory</dd>
                                    <dd>Nurseries, housing & welfare services</dd>
                                    <dd>Recreation, culture, environment</dd>
                                </dl>

                                <dl>
                                    <dt>Public sector</dt>
                                    <dd>Central government</dd>
                                    <dd>Education</dd>
                                    <dd>Health</dd>
                                    <dd>Local government</dd>
                                    <dd>Other public services</dd>
                                </dl>
                            </HelpText>
                        </HelpSection>

                        <HelpSection>
                            <HelpCaption>Job Levels Summary</HelpCaption>
                            <HelpText>
                                By default market summary data is provided by job level. This allows you to see the salary and company counts for each job level as well as the median, average, upper
                                and lower quartiles. The table below provides a breakdown of the IDR Job Levels.
                            </HelpText>
                            <HelpText>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <HeaderCell colSpan={3}>CAPSULE JOB LEVELS MATRIX</HeaderCell>
                                            <HeaderCell colSpan={3}>TYPICAL JOBS</HeaderCell>
                                        </TableRow>
                                        <TableRow>
                                            <HeaderCell>CATEGORY</HeaderCell>
                                            <HeaderCell>LEVEL</HeaderCell>
                                            <HeaderCell>DESCRIPTION</HeaderCell>
                                            <HeaderCell>Commercial</HeaderCell>
                                            <HeaderCell>Industrial</HeaderCell>
                                            <HeaderCell>Services</HeaderCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell rowSpan={2}>Administrative, manual and support</TableCell>
                                            <TableCell>1</TableCell>
                                            <TableCell>
                                                Work requires basic literacy and numeracy skills and the ability to perform a few straightforward and short-term tasks to instructions under immediate
                                                supervision. Previous experience is not necessary.
                                            </TableCell>
                                            <TableCell>Cleaner, Messenger, Filing clerk</TableCell>
                                            <TableCell>Labourer, Refuse loader</TableCell>
                                            <TableCell>Kitchen porter, Canteen assistant</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>2</TableCell>
                                            <TableCell>
                                                Work requires developed literacy and numeracy skills and the ability to perform some routine tasks within procedures that may include keyboard and
                                                practical skills and initial contact with customers. Some previous experience is required.
                                            </TableCell>
                                            <TableCell>Clerk, Finance assistant, Membership Administrator, Administrative assistant, Call centre entry operator</TableCell>
                                            <TableCell>Driver, Warehouse worker</TableCell>
                                            <TableCell>Retail assistant, Bar worker, Care assistant, Nursery assistant</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell rowSpan={2}>Secretarial/Craft</TableCell>
                                            <TableCell>3</TableCell>
                                            <TableCell>
                                                Work requires specific administrative, practical, craft or technical skills gained by previous experience and qualifications to carry out a range of
                                                less routine work and to provide specialist support, and could include closer contact with the public/customers.
                                            </TableCell>
                                            <TableCell>
                                                Administrator, Technical assistant, Sales representative - telesales, Call centre customer advisor, Sales ledger clerk, Team secretary
                                            </TableCell>
                                            <TableCell>Tiler/Handyman, Skilled road worker, Assembler, Chauffeur, LGV driver, Painter/decorator</TableCell>
                                            <TableCell>Retail assistant specialist, Care assistant, Cook</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>4</TableCell>
                                            <TableCell>
                                                Work requires broad and deep administrative, technical or craft skills and experience to carry out a wider range of activities including staff
                                                supervision, undertaking specialist routines and procedures and providing some advice.
                                            </TableCell>
                                            <TableCell>Multi skilled craft, Supervisor, Area technician, Welder, Service engineer</TableCell>
                                            <TableCell>Multi skilled craftworker, Supervisor, Area technician, Welder, Service engineer</TableCell>
                                            <TableCell>Head cook, Veterinary nurse</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell rowSpan={2}>Vocational/Supervisory</TableCell>
                                            <TableCell>5</TableCell>
                                            <TableCell>
                                                Work requires detailed experience and possibly some level of vocational qualification to be able to oversee the operation of an important procedure or
                                                to provide specialist advice and services, involving applied knowledge of internal systems and procedures.
                                            </TableCell>
                                            <TableCell>Maintenance co-ordinator, Call centre team leader, Graphics officer, Executive secretary</TableCell>
                                            <TableCell>Senior supervisor, Draughtsman, HND technician, Team leader</TableCell>
                                            <TableCell>Chef, Prison officer, Police officer, Nurse</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>6</TableCell>
                                            <TableCell>
                                                Work requires a vocational qualification and sufficient relevant specialist experience to be able to manage a section or operate with self-contained
                                                expertise in a specialist discipline or activity.
                                            </TableCell>
                                            <TableCell>Senior researcher, Quality control chemist, Computer business analyst</TableCell>
                                            <TableCell>Operations manager, Site manager, Junior engineer, Shift manager</TableCell>
                                            <TableCell>Nurse, Senior day centre officer, Probation officer, Ambulance worker - paramedic, Librarian, Restaurant manager</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell rowSpan={2}>Professional/Managerial</TableCell>
                                            <TableCell>7</TableCell>
                                            <TableCell>
                                                Work is concerned with the provision of professional services and requires an experienced and qualified professional to provide expertise and advice and
                                                operate independently. Also includes operational managers responsible for service delivery.
                                            </TableCell>
                                            <TableCell>Policy officer, head of Library services, Call centre manager, Pharmacist</TableCell>
                                            <TableCell>Electrical instrument technician, Mechanical manager</TableCell>
                                            <TableCell>Teacher, Union organiser, Social worker, veterinary surgeon, Area manager, Home care manager</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>8</TableCell>
                                            <TableCell>
                                                Work requires deep professional experience and qualifications in a specific discipline to be able to carry out a range of specialist technical or
                                                scientific activities, which may include the management of a team or services. May also include specialist management roles responsible for delivery of
                                                a major service.
                                            </TableCell>
                                            <TableCell>Finance controller, Architect, Employment lawyer, Quantity surveyor</TableCell>
                                            <TableCell>Editor, Principal engineer</TableCell>
                                            <TableCell>GP, Senior lecturer, Deputy head teacher</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Senior Management</TableCell>
                                            <TableCell>9</TableCell>
                                            <TableCell>
                                                Senior managerial roles involved in managing an important activity or providing authoritative expertise, also contributing to the organisation as a
                                                whole through significant experience.
                                            </TableCell>
                                            <TableCell>Business manager, Head of Service, Global IT service desk manager</TableCell>
                                            <TableCell>&nbsp;</TableCell>
                                            <TableCell>Hospital Consultant, Head Teacher</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Director</TableCell>
                                            <TableCell>10</TableCell>
                                            <TableCell>
                                                Member of a company board or an executive/senior management team in public sector organisations, with overall functional responsibility and input to the
                                                strategy of the wider organisation, through deep and broad experience.
                                            </TableCell>
                                            <TableCell>Finance Director, Service Director</TableCell>
                                            <TableCell>&nbsp;</TableCell>
                                            <TableCell>&nbsp;</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Chief Executive</TableCell>
                                            <TableCell>11</TableCell>
                                            <TableCell>
                                                The most senior post in an organisation responsible for setting the overall direction, strategy, financial success, and performance achievement
                                                requiring substantial business pre-eminence.
                                            </TableCell>
                                            <TableCell>Chief executive, Managing director</TableCell>
                                            <TableCell>&nbsp;</TableCell>
                                            <TableCell>&nbsp;</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </HelpText>
                        </HelpSection>
                    </Stack>
                </Paper>
            </Stack>
        </Stack>
    );
};

export default LevelsGlossary;
