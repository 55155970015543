import {Box, styled, Tab as MuiTab, Tabs as MuiTabs, Typography} from "@mui/material";

export const Tabs = styled(MuiTabs)(({theme}) => ({
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const Tab = styled(MuiTab)(({theme}) => ({
    textTransform: 'none',
    borderTop: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    background: theme.palette.contrastGrey.light,
    '&.Mui-selected': {
        background: theme.palette.background.paper,
    }
}));

export const TabPanel = ({activeTab, index, children}) => {
    return (
        <Box hidden={activeTab !== index} sx={{paddingTop: '1rem'}}>
            {activeTab === index && <>{children}</>}
        </Box>
    );
};

export const ExportLink = styled(Typography)(({theme}) => ({
    color: theme.palette.payAwards.dark,
    textTransform: "none",
    '&:hover': {
        textDecoration: "underline",
    }
}));
