import {Box, Button, Checkbox, FormControlLabel, Paper as MuiPaper, Stack, styled, TextField, Typography} from "@mui/material";
import {useAuth} from "../auth/AuthProvider";
import {useState} from "react";

const Paper = styled(MuiPaper)(() => ({
    height: '100%',
    borderWidth: 2,
    padding: '1rem',
}));

const FilterLabel = styled(Typography)(({theme}) => ({
    font: theme.typography.subtitle2.font,
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: 2,
}));

const SubmitButton = styled(Button)(({theme}) => ({
    backgroundColor: theme.palette.contrastGrey.main,
    color: '#000',
    textTransform: 'none',
    "&:hover": {
        backgroundColor: theme.palette.contrastGrey.dark,
        color: '#fff',
    },
}))

const LoginCard = ({color}) => {
    const {signIn} = useAuth();
    const [showError, setShowError] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        await signIn(data, setShowError);
    };

    return (
        <Paper variant="outlined" sx={{borderColor: `${color}.main`}}>
            <Stack spacing={2}>
                <Paper elevation={0} sx={{backgroundColor: `${color}.main`, color: `${color}.contrastText`}}>
                    <Typography variant="h6">Sign in</Typography>
                </Paper>

                {showError && (
                    <Paper elevation={0} sx={{backgroundColor: "red"}}>
                        <Typography variant="body2" sx={{color: "#fff"}}>
                            A valid account with these details could not be found
                        </Typography>
                    </Paper>
                )}

                <Box component="form" onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                        <Stack>
                            <FilterLabel>Username</FilterLabel>
                            <TextField id="username" name="username" type="text" size="small" required autoComplete="email" autoFocus/>
                        </Stack>

                        <Stack>
                            <FilterLabel>Password</FilterLabel>
                            <TextField id="password" name="password" type="password" size="small" required/>
                        </Stack>

                        <SubmitButton type="submit" variant="contained">Sign in</SubmitButton>

                        <FormControlLabel
                            control={<Checkbox name="remember" value="remember" color="primary"/>}
                            label="Remember me on this computer"
                        />
                    </Stack>
                </Box>

                {/* TODO: Reset Password */}
                <Button sx={{ textTransform: "none" }}>Reset password</Button>

            </Stack>
        </Paper>
    );
};

export default LoginCard;
