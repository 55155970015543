import {Grid2 as Grid, Paper as MuiPaper, Stack, styled, Table, TableBody as MuiTableBody, TableCell as MuiTableCell, TableHead as MuiTableHead, TableRow, Typography} from "@mui/material";
import dayjs from "dayjs";

const Paper = styled(MuiPaper)(({theme}) => ({
    background: theme.palette.contrastGrey.light,
}));

const TableHead = styled(MuiTableHead)(({theme}) => ({
    background: theme.palette.payAwards.light,
}));

const TableBody = styled(MuiTableBody)(() => ({
    background: '#fff',
}));

const TableCell = styled(MuiTableCell)(({theme}) => ({
    textAlign: "center",
    border: `1px solid ${theme.palette.contrastGrey.main}`,
    width: "120px",
}));

const TableCellContent = styled(Typography)(() => ({
    variant: 'body2',
    fontWeight: 'bold',
}));

const formatDate = (date) => {
    return dayjs(date).format('DD MMM YYYY');
};

const formatNumber = (value) => {
    return typeof value === 'number' ? `${value.toLocaleString(undefined, {minimumFractionDigits: 1})}%` : '';
};

const AwardsHeadline = ({data, label}) => {

    if (!data) {
        return <>Loading...</>;
    }

    return (
        <Paper>
            <Grid container sx={{padding: '1rem'}}>
                <Grid size="5" sx={{flexGrow: 1, overflow: "hidden", textOverflow: 'ellipsis', width: "300px"}}>
                    <Stack spacing={2}>
                        <Typography variant="h5" noWrap>{label}</Typography>
                        <Typography variant="h6">{formatDate(data.fromEffectiveDate)} - {formatDate(data.toEffectiveDate)}</Typography>
                    </Stack>
                </Grid>
                <Grid size="7">
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell><TableCellContent>Number of pay awards</TableCellContent></TableCell>
                                <TableCell><TableCellContent>Lower quartile</TableCellContent></TableCell>
                                <TableCell><TableCellContent>Median</TableCellContent></TableCell>
                                <TableCell><TableCellContent>Upper quartile</TableCellContent></TableCell>
                                <TableCell><TableCellContent>Average</TableCellContent></TableCell>
                                <TableCell><TableCellContent>Weighted average</TableCellContent></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell><TableCellContent>{data.total}</TableCellContent></TableCell>
                                <TableCell><TableCellContent>{formatNumber(data.lowerQuartile)}</TableCellContent></TableCell>
                                <TableCell><TableCellContent>{formatNumber(data.medianQuartile)}</TableCellContent></TableCell>
                                <TableCell><TableCellContent>{formatNumber(data.upperQuartile)}</TableCellContent></TableCell>
                                <TableCell><TableCellContent>{formatNumber(data.average)}</TableCellContent></TableCell>
                                <TableCell><TableCellContent>{formatNumber(data.weightedAverage)}</TableCellContent></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default AwardsHeadline;
