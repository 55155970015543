import {useEffect, useState} from "react";
import {Box} from "@mui/material";
import PieChart from "highcharts-react-official";
import Highcharts from "highcharts";
import {generateChartTitle, pieChartOptions} from "./AwardChartUtils";

const AwardsIncreaseTypePie = ({data, label}) => {
    const [chartData, setChartData] = useState([]);
    const [chartTitle, setChartTitle] = useState('');

    useEffect(() => {
        const typeCounts = {};

        data.rows.forEach((row) => {
            const count = typeCounts[row.increaseType] || 0;
            typeCounts[row.increaseType] = count + 1;
        });

        const percentValues = Object.entries(typeCounts).map(([name, value]) => {
            return {
                name: name,
                y: +(value * 100 / data.rows.length).toFixed(2)
            };
        });

        setChartData(percentValues);
        setChartTitle(generateChartTitle(data, label));

    }, [data, label]);

    const options = pieChartOptions(chartTitle, chartData);

    return (
        <Box>
            <PieChart highcharts={Highcharts} options={options}/>
        </Box>
    );
};

export default AwardsIncreaseTypePie;
