import axios from "axios";
import useSWR from "swr";
import {useAuth} from "../auth/AuthProvider";
import useSWRImmutable from "swr/immutable";

const generateConfig = (token) => {
    return {
        auth: token,
    };
};

const generateBody = (filters) => {
    const body = {
        jobFamily: filters.jobFamily > 0 ? filters.jobFamily : null,
        jobRoles: filters.jobRoles?.length > 0 ? filters.jobRoles : null,
        jobLevels: filters.jobLevels?.length > 0 ? filters.jobLevels : null,
        regions: filters.regions?.length > 0 ? filters.regions : null,
        sectors: filters.sectors?.length > 0 ? filters.sectors : null,
        industries: filters.industries?.length > 0 ? filters.industries : null,
        includeNationalRate: filters.includeNationalRate,
        anonymous: filters.anonymous,
    };

    // When salary search is at extremes then ignore it (to allow for salaries over the max)
    if (filters.salary.min !== 0 || filters.salary.max !== 150000) {
        body.salary = {
            min: filters.salary.min,
            max: filters.salary.max,
        };
    }

    return body;
};

const fetcher = (url, token, filters) => {
    const config = generateConfig(token);
    const body = generateBody(filters);
    return axios.post(url, body, config).then(response => response.data);
};

export const usePayLevelsData = (filters, view) => {
    const {token} = useAuth();
    return useSWR([`/payLevels/${view}`, filters], ([url, filters]) => fetcher(url, token, filters));
};

export const payLevelsExport = (token, filters, view) => {
    const config = {...generateConfig(token), responseType: "blob"};
    const body = generateBody(filters);
    return axios.post(`/payLevels/export/${view}`, body, config);
};

const simpleFetcher = (url, token) => {
    const config = {auth: token};
    return axios.get(url, config).then(response => response.data);
};

export const useOrganisations = () => {
    const {token} = useAuth();
    return useSWRImmutable("/payLevels/organisations", (url) => simpleFetcher(url, token));
};
