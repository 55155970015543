export const defaultChartOptions = (chartTitle) => {
    return {
        title: {
            text: chartTitle,
        },
        subtitle: {
            text: "Source: Incomes Data Research",
        },
        accessibility: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
        exporting: {
            chartOptions: {
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: true
                        }
                    }
                }
            },
            fallbackToExportServer: false
        },
    };
};
