import {useEffect, useState} from "react";
import {Box} from "@mui/material";
import ColumnChart from "highcharts-react-official";
import Highcharts from "highcharts";
import {generateChartTitle} from "./AwardChartUtils";
import {defaultChartOptions} from "../utils/ChartUtils";

const ranges = [
    {name: "range0", label: "0 %", min: 0.00, max: 0.00},
    {name: "range1", label: "0.01 - 0.99 %", min: 0.01, max: 0.99},
    {name: "range2", label: "1 - 1.99 %", min: 1.00, max: 1.99},
    {name: "range3", label: "2 - 2.99 %", min: 2.00, max: 2.99},
    {name: "range4", label: "3 - 3.99 %", min: 3.00, max: 3.99},
    {name: "range5", label: "4 - 4.99 %", min: 4.00, max: 4.99},
    {name: "range6", label: "5 - 5.99%", min: 5.00, max: 5.99},
    {name: "range7", label: "6+ %", min: 6.0, max: Infinity},
];

const findRange = (value) => {
    return ranges.find((range) => value >= range.min && value <= range.max, ranges[ranges.length - 1]);
};

const AwardsDistHistogram = ({data, label}) => {
    const [chartData, setChartData] = useState([]);
    const [chartTitle, setChartTitle] = useState('');

    useEffect(() => {
        const counts = {};

        data.rows.forEach((row) => {
            const range = findRange(row.increase);
            const count = counts[range.name] || 0;
            counts[range.name] = count + 1;
        });

        const percentValues = [];
        ranges.forEach(range => {
            const value = counts[range.name] || 0;
            const percent = +((value * 100 / data.rows.length).toFixed(2));
            percentValues.push(percent);
        });

        setChartData([{name: "Percentage of Settlements", data: percentValues}]);
        setChartTitle(generateChartTitle(data, label));

    }, [data, label]);

    const options = {
        ...defaultChartOptions(chartTitle),
        chart: {
            type: "column"
        },
        tooltip: {
            valueSuffix: "%",
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            column: {
                colorByPoint: true,
            },
        },
        xAxis: {
            categories: ranges.map(range => range.label),
            title: {
                text: "Increase %",
            }
        },
        yAxis: {
            title: {
                text: "Percentage of Settlements",
            }
        },
        series: chartData,
    };

    return (
        <Box>
            <ColumnChart highcharts={Highcharts} options={options}/>
        </Box>
    );
};

export default AwardsDistHistogram;
