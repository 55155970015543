import {Link as MuiLink, Paper as MuiPaper, Stack, styled, Typography} from "@mui/material";

const Link = styled(MuiLink)(() => ({
    textDecoration: 'none',
}));

const Paper = styled(MuiPaper)(() => ({
    height: '100%',
    borderWidth: 2,
    padding: '1rem',
}));

export const ProductCard = ({title, color, children}) => {
    return (
        <Paper variant="outlined" sx={{borderColor: `${color}.main`}}>
            <Stack spacing={2} sx={{height: '100%', justifyContent: 'space-between'}}>
                <Stack spacing={2}>
                    <Paper elevation={0} sx={{backgroundColor: `${color}.main`, color: `${color}.contrastText`}}>
                        <Typography variant="h6">{title}</Typography>
                    </Paper>
                    {children}
                </Stack>
            </Stack>
        </Paper>
    );
};

export const ProductCardWithLink = ({link, title, color, children}) => {
    return (
        <Link href={link}>
            <Paper variant="outlined" sx={{borderColor: `${color}.main`}}>
                <Stack spacing={2} sx={{height: '100%', justifyContent: 'space-between'}}>
                    <Stack spacing={2}>
                        <Paper elevation={0} sx={{backgroundColor: `${color}.main`, color: `${color}.contrastText`}}>
                            <Typography variant="h6">{title}</Typography>
                        </Paper>
                        {children}
                    </Stack>
                    <Typography variant="h1" sx={{color: `${color}.main`, lineHeight: 0.45}}>→</Typography>
                </Stack>
            </Paper>
        </Link>
    );
}
