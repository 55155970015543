import {usePayAwardsTrends} from "../../hooks/PayAwardsSearches";
import {Box} from "@mui/material";
import ColumnChart from "highcharts-react-official";
import Highcharts from "highcharts";
import {useEffect, useState} from "react";
import {generateChartTitle} from "./AwardChartUtils";
import {defaultChartOptions} from "../utils/ChartUtils";

const AwardsTrendBarChart = ({data, label, filters}) => {
    const [chartSeries, setChartSeries] = useState([]);
    const [chartTitle, setChartTitle] = useState('');
    const [chartColumns, setChartColumns] = useState([]);

    const {trends, isLoading} = usePayAwardsTrends(filters);

    useEffect(() => {

        const columnNames = [];
        const lowerQuartile = [];
        const median = [];
        const upperQuartile = [];
        const average = [];

        if (!isLoading) {
            trends?.trends?.forEach(trend => {
                columnNames.push(trend.label);
                lowerQuartile.push(trend.lowerQuartile);
                median.push(trend.medianQuartile);
                upperQuartile.push(trend.upperQuartile);
                average.push(trend.average);
            });
        }

        const series = [
            {
                name: "Lower Quartile",
                data: lowerQuartile,
            },
            {
                name: "Median",
                data: median,
            },
            {
                name: "Upper Quartile",
                data: upperQuartile,
            },
            {
                name: "Average",
                data: average,
            }
        ];

        setChartColumns(columnNames);
        setChartSeries(series);
        setChartTitle(generateChartTitle(data, label));

    }, [data, label, isLoading, trends]);

    const options = {
        ...defaultChartOptions(chartTitle),
        chart: {
            type: 'column',
        },
        tooltip: {
            valueSuffix: "%",
        },
        xAxis: {
            title: {
                text: "Effective Date",
            },
            categories: chartColumns,
        },
        yAxis: {
            title: {
                text: "Increase %",
            },
            labels: {
                format: "{value}%",
            }
        },
        series: chartSeries,
    };

    return (
        <Box>
            <ColumnChart highcharts={Highcharts} options={options}/>
        </Box>
    );
};

export default AwardsTrendBarChart;
