import {Box} from "@mui/material";
import ColumnChart from "highcharts-react-official";
import Highcharts from "highcharts";
import {useEffect, useState} from "react";
import {defaultChartOptions} from "../utils/ChartUtils";

const LevelBarChart = ({data, label}) => {
    const [chartSeries, setChartSeries] = useState([]);
    const [chartTitle, setChartTitle] = useState('');
    const [chartColumns, setChartColumns] = useState([]);

    useEffect(() => {

        const columnNames = [];
        const lowerQuartile = [];
        const median = [];
        const upperQuartile = [];
        const average = [];

        data.rows?.forEach(row => {
            columnNames.push(row.label);
            lowerQuartile.push(row.lowerQuartile);
            median.push(row.medianQuartile);
            upperQuartile.push(row.upperQuartile);
            average.push(row.average);
        });

        const series = [
            {
                name: "Lower Quartile",
                data: lowerQuartile,
            },
            {
                name: "Median",
                data: median,
            },
            {
                name: "Upper Quartile",
                data: upperQuartile,
            },
            {
                name: "Average",
                data: average,
            },
        ];

        setChartColumns(columnNames);
        setChartSeries(series);
        setChartTitle(`Salary : By ${label}`);

    }, [data, label]);

    const options = {
        ...defaultChartOptions(chartTitle),
        chart: {
            type: "column",
        },
        tooltip: {
            valuePrefix: "£",
        },
        xAxis: {
            categories: chartColumns,
            title: {
                text: label,
            },
        },
        yAxis: {
            title: {
                text: 'Salary (£ pa)',
            },
            labels: {
                format: "£{text}",
            },
        },
        series: chartSeries,
    };

    return (
        <Box>
            <ColumnChart highcharts={Highcharts} options={options}/>
        </Box>
    );
};

export default LevelBarChart;
