import dayjs from "dayjs";
import Highcharts from "highcharts";
import enableExporting from 'highcharts/modules/exporting';
import enableOfflineExporting from "highcharts/modules/offline-exporting";
import {defaultChartOptions} from "../utils/ChartUtils";

enableExporting(Highcharts);
enableOfflineExporting(Highcharts);

export const generateChartTitle = (data, label) => {
    const fromDate = dayjs(data.fromEffectiveDate).format('DD MMM YYYY');
    const toDate = dayjs(data.toEffectiveDate).format('DD MMM YYYY');
    return label + " : " + fromDate + " - " + toDate;
};

export const formatCompanyName = (value) => {
    if (value) {
        return value;
    }

    return "Name Withheld";
};

export const pieChartOptions = (chartTitle, chartData) => {
    return {
        ...defaultChartOptions(chartTitle),
        chart: {
            type: "pie"
        },
        tooltip: {
            valueSuffix: "%",
        },
        series: {
            name: "Percentage",
            data: chartData
        },
        plotOptions: {
            series: {
                dataLabels: [
                    {
                        format: '{point.name}, {point.percentage:.2f}%',
                    }
                ]
            },
        },
    };
};
