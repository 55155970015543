import axios from "axios";
import useSWR from "swr";
import {useAuth} from "../auth/AuthProvider";

const generateConfig = (token) => {
    return {
        auth: token,
    };
};

const generateBody = (filters) => {
    const showExcludedCompanies = filters.excludeCompanies?.length > 0;
    const showIncludedCompanies = filters.includeCompanies?.length > 0 && !showExcludedCompanies;

    const body = {
        sort: filters.sort,
        fromEffectiveDate: filters.fromEffectiveDate,
        toEffectiveDate: filters.toEffectiveDate,
        increaseType: filters.increaseType === "All" ? null : filters.increaseType,
        unionRecognised: filters.unionRecognised ? true : null,
        longTermDeals: filters.longTermDeals ? true : null,
        sectors: filters.sectors,
        industries: filters.industries,
        subSectors: filters.subSectors,
        includeCompanies: showIncludedCompanies ? filters.includeCompanies : null,
        excludeCompanies: filters.excludeCompanies,
        ignoredAwards: filters.ignoredAwards,
    };

    if (filters.increase.min || filters.increase.max) {
        body.increase = {
            min: filters.increase.min,
            max: filters.increase.max,
        };
    }

    if (filters.employeeCount.min || filters.employeeCount.max) {
        body.employeeCount = {
            min: filters.employeeCount.min,
            max: filters.employeeCount.max,
        };
    }

    return body;
};

const fetcher = (url, token, filters) => {
    const config = generateConfig(token);
    const body = generateBody(filters);
    return axios.post(url, body, config).then(response => response.data);
};

export const usePayAwardsData = (filters) => {
    const {token} = useAuth();
    return useSWR(["/payAwards/data", filters], ([url, filters]) => fetcher(url, token, filters));
};

export const usePayAwardsTrends = (filters) => {
    const {token} = useAuth();
    const {data: trends, isLoading} = useSWR(["/payAwards/trends", filters], ([url, filters]) => fetcher(url, token, filters));
    return {trends, isLoading};
};

export const payAwardsExport = (token, filters) => {
    const config = {...generateConfig(token), responseType: "blob"};
    const body = generateBody(filters);
    return axios.post("/payAwards/export", body, config);
};
