import {Breadcrumbs as MuiBreadcrumbs, Button, Link as MuiLink, Stack, styled, Typography} from "@mui/material";
import {NavigateNext, RestartAlt} from "@mui/icons-material";

const Link = styled(MuiLink)(() => ({
    textDecoration: 'none',
    color: 'inherit',
}));

const Breadcrumbs = styled(MuiBreadcrumbs)(() => ({
    alignContent: "center",
    backgroundColor: 'inherit',
    color: 'inherit',
}));

const HeaderBreadcrumb = ({name, color, resetFilters, links}) => {
    return (
        <Stack direction="row" spacing={0} sx={{
            justifyContent: 'space-between',
            backgroundColor: `${color}.main`,
            color: `${color}.contrastText`,
            padding: '0 1rem 0 1rem',
            height: '36px',
        }}>
            <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNext/>}>
                <Link href="/">Home</Link>
                {links?.map(link => (
                    <Link href={link.href}>{link.label}</Link>
                ))}
                <Typography sx={{fontWeight: 'bold'}}>{name}</Typography>
            </Breadcrumbs>
            {resetFilters && (
                <Button startIcon={<RestartAlt/>} onClick={resetFilters} sx={{color: `${color}.contrastText`}}>Reset</Button>
            )}
        </Stack>
    );
};

export default HeaderBreadcrumb;
