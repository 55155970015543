import axios from "axios";
import useSWRImmutable from "swr/immutable";
import {useAuth} from "../auth/AuthProvider";

const fetcher = (url, token) => {
    const config = {auth: token};
    return axios.get(url, config).then(response => response.data);
};

export const useSectors = () => {
    const {token} = useAuth();
    const {data: sectors} = useSWRImmutable("/reference/sectors", (url) => fetcher(url, token));
    return {sectors};
};

export const useIndustries = (sectorId) => {
    const {token} = useAuth();
    const url = Boolean(sectorId) ? `/reference/sectors/${sectorId}/industries` : null;
    const {data: industries} = useSWRImmutable(url, (url) => fetcher(url, token));
    return {industries};
};

export const useSubSectors = (industryId) => {
    const {token} = useAuth();
    const url = Boolean(industryId) ? `/reference/sectors/industries/${industryId}/subsectors` : null;
    const {data: subSectors} = useSWRImmutable(url, (url) => fetcher(url, token));
    return {subSectors};
};

export const useCompanies = (subSectorId, letter) => {
    const {token} = useAuth();
    let url = null;
    if (Boolean(subSectorId)) {
        url = `/reference/sectors/industries/subsectors/${subSectorId}/companies`;
    } else if (Boolean(letter)) {
        url = `/reference/companies/${letter.id}`;
    }

    const {data: companies} = useSWRImmutable(url, (url) => fetcher(url, token));
    return {companies};
};

export const useJobFamilies = () => {
    const {token} = useAuth();
    const {data: jobFamilies} = useSWRImmutable('/reference/job/families', (url) => fetcher(url, token));
    return {jobFamilies};
};

export const useJobTitles = (jobFamily) => {
    const {token} = useAuth();
    const url = Boolean(jobFamily) ? `/reference/job/titles?family=${jobFamily}` : '/reference/job/titles';
    const {data: jobTitles} = useSWRImmutable(url, (url) => fetcher(url, token));
    return {jobTitles};
};

export const useRegions = () => {
    const {token} = useAuth();
    const {data: regions, isLoading} = useSWRImmutable('/reference/regions', (url) => fetcher(url, token));
    return {regions, isLoading};
};

export const useSectorsAndIndustries = () => {
    const {token} = useAuth();
    const {data: sectors, isLoading} = useSWRImmutable('/reference/sectorsAndIndustries', (url) => fetcher(url, token));
    return {sectors, isLoading};
};
