import {DataGrid} from "@mui/x-data-grid";

const formatSalary = (value) => {
    const zeroPlaces = +Number(value.toFixed(0));
    return `£${zeroPlaces.toLocaleString()}`;
};

const LevelsDataGrid = ({data, firstColumn}) => {

    const columns = [
        {
            field: "id",
            headerName: "ID",
            filterable: false,
        },
        {
            field: 'label',
            headerName: firstColumn,
            flex: 1,
        },
        {
            field: "salaryCount",
            headerName: "Salary Count",
            headerAlign: "right",
            align: "right",
            flex: 0.5,
        },
        {
            field: "companyCount",
            headerName: "Company Count",
            headerAlign: "right",
            align: "right",
            flex: 0.55,
        },
        {
            field: "lowerQuartile",
            headerName: "Lower Quartile",
            headerAlign: "right",
            align: "right",
            flex: 0.5,
            valueFormatter: (value) => formatSalary(value),
        },
        {
            field: "medianQuartile",
            headerName: "Median",
            headerAlign: "right",
            align: "right",
            flex: 0.35,
            valueFormatter: (value) => formatSalary(value),
        },
        {
            field: "upperQuartile",
            headerName: "Upper Quartile",
            headerAlign: "right",
            align: "right",
            flex: 0.5,
            valueFormatter: (value) => formatSalary(value),
        },
        {
            field: "average",
            headerName: "Average",
            headerAlign: "right",
            align: "right",
            flex: 0.35,
            valueFormatter: (value) => formatSalary(value),
        },
    ];

    const getTogglableColumns = (columns) => {
        return columns.filter((column) => column.field !== "id").map((column) => column.field);
    };

    return (
        <DataGrid
            rows={data.rows}
            columns={columns}
            density="compact"
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        id: false,
                    },
                },
                pagination: {
                    paginationModel: {
                        pageSize: 20,
                    },
                },
            }}
            pageSizeOptions={[20]}
            slotProps={{
                columnsManagement: {
                    getTogglableColumns,
                },
            }}
            sx={{
                '& .MuiDataGrid-columnHeader': {
                    backgroundColor: 'contrastGrey.light',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                }
            }}
        />
    );
};

export default LevelsDataGrid;
