import {List as MuiList, ListItem, ListItemButton as MuiListItemButton, ListItemText, Paper, styled} from "@mui/material";
import {useEffect, useState} from "react";

const List = styled(MuiList)(({theme}) => ({
    background: theme.palette.background.default,
    paddingTop: 0,
    paddingBottom: 0,
    border: `1px solid ${theme.palette.contrastGrey.main}`,
}));

const ListItemButton = styled(MuiListItemButton)(({theme}) => ({
    padding: "0 0.5rem 0 0.5rem",
    '&.Mui-selected': {
        backgroundColor: theme.palette.contrastGrey.main,
    }
}));

const employeeCountRanges = [
    {
        key: "1-100",
        label: "1 - 100",
        values: {min: 1, max: 100}
    },
    {
        key: "100-500",
        label: "100 - 500",
        values: {min: 100, max: 500}
    },
    {
        key: "500-1000",
        label: "500 - 1,000",
        values: {min: 500, max: 1000}
    },
    {
        key: "1000-2500",
        label: "1,000 - 2,500",
        values: {min: 1000, max: 2500}
    },
    {
        key: "2500-5000",
        label: "2,500 - 5,000",
        values: {min: 2500, max: 5000}
    },
    {
        key: "5000-10000",
        label: "5,000 - 10,000",
        values: {min: 5000, max: 10000}
    },
    {
        key: "10000-20000",
        label: "10,000 - 20,000",
        values: {min: 10000, max: 20000}
    },
    {
        key: "20000-30000",
        label: "20,000 - 30,000",
        values: {min: 20000, max: 30000}
    },
    {
        key: "30000-40000",
        label: "30,000 - 40,000",
        values: {min: 30000, max: 40000}
    },
    {
        key: "40000-50000",
        label: "40,000 - 50,000",
        values: {min: 40000, max: 50000}
    },
    {
        key: "50000-75000",
        label: "50,000 - 75,000",
        values: {min: 50000, max: 75000}
    },
    {
        key: "75000-100000",
        label: "75,000 - 100,000",
        values: {min: 75000, max: 100000}
    },
    {
        key: "100000-250000",
        label: "100,000 - 250,000",
        values: {min: 100000, max: 250000}
    },
    {
        key: "250000-500000",
        label: "250,000 - 500,000",
        values: {min: 250000, max: 500000}
    },
    {
        key: "500000-1000000",
        label: "500,000 - 1 million",
        values: {min: 500000, max: 1000000}
    },
    {
        key: "1000000-7000000",
        label: "1 million +",
        values: {min: 1000000, max: 7000000}
    },
];

const EmployeeCountFilter = ({low, high, updateFilters}) => {
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        let start = employeeCountRanges.findIndex((item) => item.values.min === low);
        let end = employeeCountRanges.findIndex((item) => item.values.max === high);

        if (start === -1) {
            start = end;
        }

        if (end === -1) {
            end = start;
        }

        if (start !== -1 && end !== -1) {
            const currentSelection = Array.from({length: end - start + 1}, (x, i) => i + start);
            setSelected(currentSelection);
        } else {
            setSelected([]);
        }

    }, [low, high]);

    const handleEmployeeCountClick = (index) => {

        const minElement = Math.min(...selected);
        const maxElement = Math.max(...selected);

        let currentSelection;
        if (isSelected(index) && (minElement === index || maxElement === index)) {
            currentSelection = selected.filter(item => item !== index);
        } else {
            const start = Math.min(index, minElement);
            const end = Math.max(index, maxElement);
            currentSelection = Array.from({length: end - start + 1}, (x, i) => i + start);
        }

        if (currentSelection.length === 0) {
            updateFilters(null, null);
        } else {
            const lowValueIndex = Math.min(...currentSelection);
            const highValueIndex = Math.max(...currentSelection);
            updateFilters(employeeCountRanges[lowValueIndex].values.min, employeeCountRanges[highValueIndex].values.max);
        }

        setSelected(currentSelection);
    };

    const isSelected = (index) => {
        return selected.includes(index);
    };

    return (
        <Paper sx={{overflow: 'auto', maxHeight: '24.5rem'}}>
            <List>
                {employeeCountRanges.map((range, index) => {
                    return (
                        <ListItem key={range.key} disablePadding>
                            <ListItemButton dense divider onClick={() => handleEmployeeCountClick(index)} selected={isSelected(index)}>
                                <ListItemText primary={range.label}/>
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        </Paper>
    );
};

export default EmployeeCountFilter;
