import {DataGrid, gridStringOrNumberComparator} from "@mui/x-data-grid";
import dayjs from "dayjs";
import {useEffect, useState} from "react";
import {formatCompanyName} from "./AwardChartUtils";
import _ from "lodash";

const convertToDate = (value) => {
    return dayjs(value, "YYYY-MM-DD").toDate();
};

const formatDate = (value) => {
    const date = dayjs(value);
    return date.format("DD/MM/YY");
};

// Sort nulls to bottom of data
const companyNameComparator = (v1, v2, param1, param2) => {

    if (v1 === null || v2 === null) {
        if (v1 === v2) {
            return 0;
        }

        return v1 == null ? 1 : -1;
    }

    return gridStringOrNumberComparator(v1, v2, param1, param2);
};

const columns = [
    {
        field: "id",
        headerName: "ID",
        filterable: false,
    },
    {
        field: "effectiveDate",
        headerName: "Effective Date",
        type: "date",
        align: "center",
        flex: 0.8,
        valueGetter: (value) => convertToDate(value),
        valueFormatter: (value) => formatDate(value),
    },
    {
        field: "companyName",
        headerName: "Organisation",
        flex: 2,
        valueFormatter: (value) => formatCompanyName(value),
        sortComparator: (v1, v2, param1, param2) => companyNameComparator(v1, v2, param1, param2),
    },
    {
        field: "increase",
        headerName: "% Increase",
        type: "number",
        align: "right",
        flex: 0.7,
    },
    {
        field: "increaseType",
        headerName: "Increase Type",
        flex: 0.8,
    },
    {
        field: "employeeGroup",
        headerName: "Employee Group",
        flex: 2,
    },
    {
        field: "numberOfEmployees",
        headerName: "No. employees",
        type: "number",
        align: "right",
        flex: 0.85,
    },
    {
        field: "longTermDeal",
        headerName: "Long Term Deal",
        align: "center",
        flex: 0.9,
        valueGetter: (value) => value ? "Yes" : "No",
    },
    {
        field: "unionRecognised",
        headerName: "Union recognised",
        align: "center",
        flex: 0.9,
        valueGetter: (value) => value ? "Yes" : "No",
    },
    {
        field: "unionDetails",
        headerName: "Unions",
        flex: 2,
    },
    {
        field: "sectorName",
        headerName: "Sector",
        flex: 2,
    },
    {
        field: "industryName",
        headerName: "Industry",
        flex: 2,
    },
    {
        field: "subSectorName",
        headerName: "Sub Sector",
        flex: 2,
    },
];

const AwardsDataGrid = ({data, filters, updateFilters}) => {
    const [rowSelectionModel, setRowSelectionModel] = useState([]);

    useEffect(() => {
        const shouldBeSelected = _.difference(availableItems(data), filters.ignoredAwards);
        setRowSelectionModel(shouldBeSelected);
    }, [data, filters]);

    const availableItems = (data) => {
        return data?.rows ? data?.rows.map((item) => item.id) : [];
    };

    const updateRowSelection = (newRowSelectionModel) => {
        filters.ignoredAwards = _.difference(filters.ignoredAwards, newRowSelectionModel);
        const ignored = _.difference(availableItems(data), newRowSelectionModel);
        filters.ignoredAwards = _.union(filters.ignoredAwards, ignored);

        updateFilters(filters);
    };

    const getTogglableColumns = (columns) => {
        return columns.filter((column) => column.field !== "id").map((column) => column.field);
    };

    const rows = data?.rows ? data.rows : [];

    return (
        <DataGrid
            rows={rows}
            columns={columns}
            checkboxSelection
            disableRowSelectionOnClick
            density="compact"
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        id: false,
                        increaseType: false,
                        longTermDeal: false,
                        unionRecognised: false,
                        unionDetails: false,
                        sectorName: false,
                        industryName: false,
                        subSectorName: false,
                    },
                },
                pagination: {
                    paginationModel: {
                        pageSize: 20,
                    },
                },
                sorting: {
                    sortModel: [{field: 'companyName', sort: 'asc'}],
                },
            }}
            pageSizeOptions={[20]}
            onRowSelectionModelChange={updateRowSelection}
            rowSelectionModel={rowSelectionModel}
            slotProps={{
                columnsManagement: {
                    getTogglableColumns,
                },
            }}
            sx={{
                '& .MuiDataGrid-columnHeader': {
                    backgroundColor: 'contrastGrey.light',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'bold',
                }
            }}
        />
    );
};

export default AwardsDataGrid;
