import {Slider, Stack, styled, TextField as MuiTextField} from "@mui/material";
import {useEffect, useState} from "react";

const TextField = styled(MuiTextField)(({theme}) => ({
    backgroundColor: theme.palette.background.paper,
}));

const SliderWithEdit = ({low, high, min, max, step, fixed, updateValues}) => {
    const [value, setValue] = useState([min, max]);

    const enforceMin = (value) => Math.max(value, min);
    const enforceMax = (value) => Math.min(value, max);
    const fixedValue = (value) => +Number(value).toFixed(fixed);
    const fixedText = (value) => Number(value).toFixed(fixed);
    const forceNumber = (value) => value === '' ? 0 : fixedValue(value);

    useEffect(() => {
        const newValue = [
            typeof low === 'number' ? low : min,
            typeof high === 'number' ? high : max,
        ];
        setValue(newValue);
    }, [low, high, min, max]);

    const handleSliderChange = (event, newValue) => {
        const rounded = [
            fixedValue(newValue[0]),
            fixedValue(newValue[1]),
        ];
        setValue(rounded);
        updateValues(rounded[0], rounded[1]);
    };

    const handleTextChange = (event) => {
        const newValue = forceNumber(event.target.value);
        if (event.target.id === "low-range") {
            updateValues(enforceMin(newValue), enforceMax(value[1]));
            setValue([event.target.value, value[1]]);
        } else {
            updateValues(enforceMin(value[0]), enforceMax(newValue));
            setValue([value[0], event.target.value]);
        }
    };

    const handleTextBlur = () => {
        let lowValue = enforceMin(enforceMax(value[0]));
        let highValue = enforceMin(enforceMax(value[1]));
        setValue([lowValue, highValue]);
    };

    return (
        <Stack>
            <Slider
                value={[forceNumber(value[0]), forceNumber(value[1])]}
                onChange={handleSliderChange}
                valueLabelDisplay="auto"
                valueLabelFormat={fixedText}
                min={min}
                max={max}
                step={step}
                shiftStep={step}
            />
            <Stack direction="row" spacing={2} sx={{justifyContent: "center"}}>
                <TextField
                    id="low-range"
                    size="small"
                    value={value[0]}
                    onChange={handleTextChange}
                    onBlur={handleTextBlur}
                    sx={{width: "100px"}}
                    slotProps={{
                        htmlInput: {
                            step: step,
                            min: min,
                            max: max,
                            type: 'number',
                        }
                    }}
                />
                <TextField
                    id="high-range"
                    size="small"
                    value={value[1]}
                    onChange={handleTextChange}
                    onBlur={handleTextBlur}
                    sx={{width: "100px"}}
                    slotProps={{
                        htmlInput: {
                            step: step,
                            min: min,
                            max: max,
                            type: 'number',
                        }
                    }}
                />
            </Stack>
        </Stack>
    );
};

export default SliderWithEdit;
