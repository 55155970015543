import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Navigate, Route, Routes} from "react-router";
import axios from "axios";
import AwardsGlossary from "./pages/AwardsGlossary";
import Help from "./pages/Help";
import LevelsGlossary from "./pages/LevelsGlossary";
import MyDetails from "./pages/MyDetails";
import PayAwards from "./pages/PayAwards";
import PayLevels from "./pages/PayLevels";
import {createTheme, ThemeProvider} from "@mui/material";
import Home from "./pages/Home";
import AuthProvider from "./auth/AuthProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";
import 'dayjs/locale/en-gb'
import ProtectedRoute from "./components/ProtectedRoute";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

const theme = createTheme({
    palette: {
        payLevels: {
            main: '#00e3b8',
            light: '#d4f9f0',
            dark: '#00c48a',
            contrastText: '#fff'
        },
        payAwards: {
            main: '#00d2ff',
            light: '#adebfd',
            dark: '#00baff',
            contrastText: '#fff'
        },
        admin: {
            main: '#2c0064',
            light: '#59047c',
            dark: '#000000',
            contrastText: '#fff'
        },
        contrastGrey: {
            main: '#bdbdbd',
            light: '#eeeeee',
            dark: '#757575',
        }
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <AuthProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en-gb"}>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<App stickyFooter={true}/>}>
                                <Route index element={<Home/>}/>
                            </Route>
                            <Route path="/levels" element={<App/>}>
                                <Route index element={<ProtectedRoute><PayLevels/></ProtectedRoute>}/>
                            </Route>
                            <Route path="/awards" element={<App/>}>
                                <Route index element={<ProtectedRoute><PayAwards/></ProtectedRoute>}/>
                            </Route>
                            <Route path="/my_details" element={<App stickyFooter={true}/>}>
                                <Route index element={<ProtectedRoute><MyDetails/></ProtectedRoute>}/>
                            </Route>
                            <Route path="/help" element={<App stickyFooter={true}/>}>
                                <Route index element={<Help/>}/>
                            </Route>
                            <Route path="/help/levels" element={<App/>}>
                                <Route index element={<LevelsGlossary/>}/>
                            </Route>
                            <Route path="/help/awards" element={<App/>}>
                                <Route index element={<AwardsGlossary/>}/>
                            </Route>
                            <Route path="*" element={<Navigate to="/" replace/>}/>
                        </Routes>
                    </BrowserRouter>
                </ThemeProvider>
            </LocalizationProvider>
        </AuthProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
