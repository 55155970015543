import {Button as MuiButton, Stack, styled} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";

const Button = styled(MuiButton)(() => ({
    textTransform: "none",
}));

const formatDate = (date) => {
    return date.format("YYYY-MM-DD");
};

const AwardsDateFilters = ({filters, updateFilters}) => {

    const updateEffectiveFromDate = (value) => {
        filters.fromEffectiveDate = formatDate(value);
        updateFilters();
    };

    const updateEffectiveToDate = (value) => {
        filters.toEffectiveDate = formatDate(value);
        updateFilters();
    };

    const selectMonthRange = (value) => {
        const end = dayjs();
        const start = end.subtract(value, "month").add(1, "day");

        filters.fromEffectiveDate = formatDate(start);
        filters.toEffectiveDate = formatDate(end);

        updateFilters();
    };

    return (
        <Stack direction="row" spacing={2} sx={{justifyContent: "right"}}>
            <Button variant="text" onClick={() => selectMonthRange(12)}>12 Months</Button>
            <Button variant="text" onClick={() => selectMonthRange(6)}>6 Months</Button>
            <Button variant="text" onClick={() => selectMonthRange(3)}>3 Months</Button>
            <DatePicker
                value={dayjs(filters.fromEffectiveDate)}
                onChange={updateEffectiveFromDate}
                slotProps={{textField: {size: 'small'}}}
            />
            <DatePicker
                onChange={updateEffectiveToDate}
                value={dayjs(filters.toEffectiveDate)}
                slotProps={{textField: {size: 'small'}}}
            />
        </Stack>
    );
};

export default AwardsDateFilters;
