import {createContext, useContext, useState} from "react";
import axios from "axios";

const AuthContext = createContext();

const storeLocalData = (key, json, remember) => {
    const str = JSON.stringify(json);
    sessionStorage.setItem(key, str);
    if (remember) {
        localStorage.setItem(key, str);
    }
}

const fetchLocalData = (key) => {
    const sessionData = sessionStorage.getItem(key);
    if (sessionData) {
        return JSON.parse(sessionData);
    }

    const localData = localStorage.getItem(key);
    if (localData) {
        return JSON.parse(localData);
    }

    return undefined;
}

const AuthProvider = ({children}) => {
    const [user, setUser] = useState(fetchLocalData("user"));
    const [token, setToken] = useState(fetchLocalData("token"));

    const signIn = async (formData, setLoginFailed) => {
        axios.post("/auth/login", formData)
            .then(response => {
                const remember = Boolean(formData.get("remember"));

                storeLocalData("user", response.data, remember);
                setUser(response.data);

                const auth = {
                    username: formData.get("username"),
                    password: formData.get("password"),
                };

                storeLocalData("token", auth, remember);
                setToken(auth);

                setLoginFailed(false);
            })
            .catch(() => {
                setLoginFailed(true);
            });
    };

    const signOut = async () => {
        sessionStorage.clear();
        localStorage.clear();
        setUser(undefined);
        setToken(undefined);
    };

    return (
        <AuthContext.Provider value={{user, token, signIn, signOut}}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
