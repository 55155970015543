import {Box, Button, Paper, Stack, styled, TextField, Typography} from "@mui/material";
import HeaderBreadcrumb from "../components/HeaderBreadcrumb";
import {useAuth} from "../auth/AuthProvider";

const Section = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.admin.light,
    color: theme.palette.admin.contrastText,
}));

const Title = styled(Typography)(({theme}) => ({
    padding: '0.5rem',
    fontSize: theme.typography.h6.fontSize,
}));

const SubmitButton = styled(Button)(({theme}) => ({
    backgroundColor: theme.palette.contrastGrey.main,
    color: '#000',
    textTransform: 'none',
    "&:hover": {
        backgroundColor: theme.palette.contrastGrey.dark,
        color: '#fff',
    },
}));

const MyDetails = () => {
    const {user} = useAuth();

    const handleSubmit = (event) => {
        event.preventDefault();
        // TODO: Change password
    };

    return (
        <Stack>
            <HeaderBreadcrumb name="My Details" color="admin"/>
            <Stack sx={{padding: '1rem'}} spacing={2}>
                <Paper variant="outlined" sx={{border: 'none'}}>
                    <Stack spacing={2}>
                        <Section elevation={0}>
                            <Title>My Details</Title>
                        </Section>
                        <Stack sx={{padding: '0 0 1rem 1rem'}} spacing={2}>
                            <Typography>Name: {user.firstName} {user.lastName}</Typography>
                            <Typography>Username: {user.email}</Typography>
                        </Stack>
                    </Stack>
                </Paper>

                <Paper variant="outlined" sx={{border: 'none'}}>
                    <Stack spacing={2}>
                        <Section elevation={0}>
                            <Title>Change Password</Title>
                        </Section>

                        <Box component="form" onSubmit={handleSubmit}>
                            <Stack sx={{padding: '0 0 1rem 1rem', width: "30%"}} spacing={2}>
                                <Stack>
                                    <Typography>Old password</Typography>
                                    <TextField id="old_password" name="old_password" type="text" size="small" required autoFocus/>
                                </Stack>

                                <Stack>
                                    <Typography>New password (at least 6 characters)</Typography>
                                    <TextField
                                        id="new_password"
                                        name="new_password"
                                        type="text"
                                        size="small"
                                        required
                                        slotProps={{
                                            htmlInput: {
                                                minLength: 6,
                                            }
                                        }}
                                    />
                                </Stack>

                                <Stack>
                                    <Typography>Retype new password</Typography>
                                    <TextField id="confirm_password" name="confirm_password" type="text" size="small" required/>
                                </Stack>

                                <SubmitButton type="submit" variant="contained">Submit</SubmitButton>
                            </Stack>
                        </Box>
                    </Stack>
                </Paper>
            </Stack>
        </Stack>
    );
};

export default MyDetails;
